import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles({
    drawer: {
        width: '35vw',
        // width: drawerWidth,
        transition: 'width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;',
        // transition: theme.transitions.create(['margin', 'width'], {
        // 	easing: theme.transitions.easing.easeOut,
        // 	duration: theme.transitions.duration.enteringScreen,
        // }),
    },
    closeDrawerButtonWrapper: {
        display: 'grid',
        justifyContent: 'end',
        height: '50px',
    },
});

const CustomDrawer = ({ pingId, children }) => {
    const classes = useStyles();

    return (
        <Drawer
            variant="persistent"
            anchor="right"
            classes={{ paper: classes.drawer, }}
            open={pingId !== null}
        >
            {children}
        </Drawer>
    );
}

export default CustomDrawer;

class TrackersService {
	init() {
		this.initGoogle();
		this.initFacebook();
	}

	trackSignup() {
		this.gtag_report_conversion();
		this.reportSignupToFacebook();
	}

	initGoogle() {
		window.dataLayer = window.dataLayer || [];
		this.gtag('js', new Date());
		this.gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS_AW);
	}

	initFacebook() {
		(function (f, b, e, v, n, t, s) {
			if (f.fbq) return;
			n = f.fbq = function () {
				n.callMethod
					? n.callMethod.apply(n, arguments)
					: n.queue.push(arguments);
			};
			if (!f._fbq) f._fbq = n;
			n.push = n;
			n.loaded = !0;
			n.version = '2.0';
			n.queue = [];
			t = b.createElement(e);
			t.async = !0;
			t.src = v;
			s = b.getElementsByTagName(e)[0];
			s.parentNode.insertBefore(t, s);
		})(
			window,
			document,
			'script',
			'https://connect.facebook.net/en_US/fbevents.js'
		);
		window.fbq('init', process.env.REACT_APP_FACEBOOK_ANALYTICS_KEY);
		window.fbq('track', 'CompleteRegistration');
	}

	gtag() {
		window.dataLayer.push(arguments);
	}

	gtag_report_conversion(url) {
		console.log('gtag_report_conversion');

		var callback = function () {
			if (typeof url != 'undefined') {
				window.location = url;
			}
		};
		this.gtag('event', 'conversion', {
			send_to:
				process.env.REACT_APP_GOOGLE_ANALYTICS_AW + '/zmVQCL_7pOsBEJvy5uwB',
			transaction_id: '',
			event_callback: callback,
		});
		return false;
	}

	reportSignupToFacebook() {
		console.log('reportSignupToFacebook');
		window.fbq('track', 'CompleteRegistration');
	}
}
export let trackersService = new TrackersService();

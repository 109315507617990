import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import SendIcon from '@material-ui/icons/Send';
import InputBase from '@material-ui/core/InputBase';
import {
	Button,
	Grid,
	Typography,
	IconButton,
	Divider,
	Dialog,
	Paper,
	Switch,
	FormControlLabel,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ChatUI from './ChatUI';
import { pingService } from '../../services/PingService';
import { DateTime } from 'luxon';

const styles = {
	root: {
		padding: '1em',
		height: '100%',
		overflowX: 'hidden',
	},
	messageInput: {
		border: 'solid #00000091 1px;',
		borderRadius: 40,
		paddingLeft: '10px',
	},
	messageInputInner: {
		padding: '10px',
	},
	header: {
		// height: '200px',
		display: 'grid',
		gridTemplateColumns: 'auto auto',
		alignItems: 'start',
		// justifyContent: 'space-around',
	},
	closeDrawerButtonWrapper: {
		justifyContent: 'flex-end',
		display: 'flex',
		flexDirection: 'row',
	},
	primaryField: {
		// fontWeight: 600,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '70%',
	},
	dot: {
		// marginRight: '1em',
		height: '0.5em',
		width: '0.5em',
		backgroundColor: '#bbb',
		borderRadius: '50%',
		display: 'inline-block',
	},
	startTime: {
		// textAlign: 'right',
	},
	duration: {
		display: 'flex',
		flexDirection: 'column',
	},
	bottom: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	},
	fieldName: {
		fontWeight: 600,
	},
	fieldValue: {
		margin: 0,
	},
	form: {
		height: '50px',
		padding: '5px',
		display: 'flex',
		justifyContent: 'space-around',
		position: 'sticky',
		bottom: 0,
		backgroundColor: '#fff',
	},
};

const dateFormatter = new Intl.DateTimeFormat('en-US', {
	hour: 'numeric',
	minute: 'numeric',
});

class PingDetails extends React.Component {
	state = {
		minutes: 0,
		hours: 0,
		color: '',
		isOnline: 'true',
		open: false,
		messageVal: '',
		allowResponse: this.props.allowResponse
	};

	findPrimary(fields) {
		return fields.find((field) => field.field.isPrimary);
	}

	sendMessage = (e) => {
		e.preventDefault();
		const message = e.target?.elements?.message?.value;
		if (e.target?.elements?.message?.value)
			e.target.elements.message.value = '';
		pingService.sendMessage(this.props.ping.id, message);
		this.setState({ messageVal: '' });
	};

	closePingSuccess = () => {
		pingService.closeSuccess(this.props.ping.id);
	};

	closePingError = () => {
		pingService.closeError(this.props.ping.id);
	};

	responseChange = (e) => {
		pingService.responseChange(this.props.ping.id, e.target.checked);
		this.setState({ allowResponse: e.target.checked });
	};

	opener = () => {
		this.setState({ open: !this.state.open });
	};

	presetMessagePopulator = (e) => {
		e.preventDefault();
		this.setState({ messageVal: e.target.innerText });
		this.opener();
	};

	messageHandler = (e) => {
		e.preventDefault();
		this.setState({ messageVal: e.target.value });
	};

	timer = () => {
		setInterval(
			function () {
				this.setState({ color: this.props.ping.color });
				this.setState({ isOnline: this.props.ping.isOnline });
				this.setState({ minutes: +this.state.minutes + 1 });
				if (this.state.minutes == 60) {
					this.setState({ minutes: 0, hours: this.state.hours + 1 });
				}
			}.bind(this),
			60 * 1000
		); // 60 * 1000 milsec
	};

	componentDidMount() {
		if (this.duration) {
			this.setState({ hours: this.duration.hours });
			this.setState({
				minutes: this.duration.minutes.toString().split('.')[0],
			});
			this.setState({ color: this.props.ping.color });
		}
		this.timer();
	}

	startTime = DateTime.fromISO(this.props.ping.dateTimeStarted);
	currentTime = DateTime.now();
	duration = this.currentTime.diff(this.startTime, ['hours', 'minutes']);

	render() {
		const classes = this.props.classes;
		this.dotColor = this.state.isOnline ? '#82A98B' : '#ffd544';
		return (
			<Grid container className={classes.root}>
				<Grid container className={classes.header}>
					{/* <Button
						color="primary"
						variant="contained"
						size="small"
						onClick={this.closePingSuccess}
					>
						Done
					</Button>
					<Button
						color="primary"
						variant="outlined"
						size="small"
						onClick={this.closePingError}
					>
						Close chat
					</Button> */}
					<Grid item className={classes.primaryField}>
						<div
							className={classes.dot}
							style={{ backgroundColor: this.dotColor }}
						/>
						<Typography variant="h4" style={{ fontWeight: 700 }}>
							{this.findPrimary(this.props.ping.fields)?.value}
						</Typography>
					</Grid>
					<Grid item className={classes.closeDrawerButtonWrapper}>
						<Grid
							item
							xs={5}
							style={{ marginBottom: 'auto' }}
							onClick={this.closePingSuccess}
						>
							<Typography
								variant="h6"
								style={{
									fontWeight: 700,
									textDecoration: 'underline',
									cursor: 'pointer',
								}}
							>
								End Chat
							</Typography>
						</Grid>
						<Grid item xs={2}>
							<IconButton
								onClick={this.props.closeDetails}
								aria-label="close"
								style={{
									padding: 0,
									paddingLeft: '5px',
								}}
							>
								<CloseIcon fontSize="large" style={{ fontWeight: 700 }} />
							</IconButton>
						</Grid>
					</Grid>
				</Grid>
				<Grid container className="secondRow" spacing={2}>
					<Grid item xs={6} md={3}>
						<Typography variant="subtitle2">Order ID:</Typography>
						<Typography variant="subtitle1">
							{this.findPrimary(this.props.ping.fields)?.value}
						</Typography>
					</Grid>
					<Grid item xs={6} md={3} className={classes.duration}>
						<Typography variant="subtitle2">Duration:</Typography>
						{this.duration.values && (
							<Typography
								variant="subtitle1"
								style={{ whiteSpace: 'nowrap' }}
								// style={{ whiteSpace: 'nowrap', marginLeft: '-2em' }}
							>
								{this.duration.hours > 0 && (
									<span>{this.state.hours} Hours </span>
								)}{' '}
								{
									<span>
										{/* {this.duration.minutes.toString().split('.')[0]} Minutes{' '} */}
										{this.state.minutes} Minutes{' '}
									</span>
								}
							</Typography>
						)}
					</Grid>
					{/* <Grid item xs={2}>
							<dl className={classes.displayFields}>
								{this.props.ping.fields.map((f, i) => (
									<React.Fragment key={i}>
										<dt className={classes.fieldName}>{f.field.name}</dt>
										<dd className={classes.fieldValue}>{f.value}</dd>
									</React.Fragment>
								))}
							</dl>
					</Grid> */}
					<Grid item xs={6} md={3} className={classes.startTime}>
						<Typography variant="subtitle2">Started</Typography>
						<Typography variant="subtitle1">
							{dateFormatter.format(new Date(this.props.ping.dateTimeStarted))}
						</Typography>
					</Grid>
					<Grid item xs={6} md={3}>
						<Typography variant="subtitle2">Responsive</Typography>
						<FormControlLabel
							control={
								<Switch
									color="primary"
									checked={
										this.props.ping.allowResponse || this.state.allowResponse
									}
								/>
							}
							onChange={this.responseChange}
						/>
					</Grid>
				</Grid>
				{/* <Divider style={{backgroundColor: 'black'}}/> */}
				<hr
					style={{ borderTop: '2px solid #bbb', width: '100%', height: '2px' }}
				/>
				<Grid container className={classes.bottom}>
					<ChatUI
						messageList={this.props.ping.chatMessageList}
						style={{ width: '100%' }}
					/>
					<form
						className={classes.form}
						onSubmit={this.sendMessage}
						style={{ width: '100%' }}
					>
						<Dialog
							open={this.state.open}
							onClose={this.opener}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						>
							<Paper style={{ padding: '2em' }}>
								<Grid container alignContent="center" direction="column">
									<Grid item>
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
											}}
										>
											<h2>Canned Messages</h2>
											<CloseIcon onClick={this.opener} />
										</div>
									</Grid>
									<Grid item>
										{this.props.presetMessages.map((message) => (
											<div
												key={message.id}
												onClick={this.presetMessagePopulator}
												style={{ cursor: 'pointer' }}
											>
												<h4>{message.message}</h4>
											</div>
										))}
									</Grid>
								</Grid>
							</Paper>
						</Dialog>
						<IconButton onClick={this.opener}>
							<MoreVertIcon />
						</IconButton>
						<InputBase
							required
							className={classes.messageInput}
							name="message"
							placeholder="Message..."
							value={this.state.messageVal}
							onChange={this.messageHandler}
							// inputProps={{
							// 	className: classes.messageInputInner,
							// 	list: 'preset-messages',
							// 	required: true,
							// }}
							style={{ width: '100%' }}
						/>
						{/* <datalist id="preset-messages">
							{this.props.presetMessages.map((pm, i) => (
								<option key={i}>{pm.message}</option>
							))}
						</datalist> */}
						<Fab type="submit" color="primary" size="small">
							<SendIcon />
						</Fab>
					</form>
				</Grid>
			</Grid>
		);
	}
}

export default withStyles(styles)(PingDetails);

import React from 'react';
import { Box, Typography, Grid, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { pingService } from '../../services/PingService';
import { DateTime } from 'luxon';

// set text styles with Typography

const styles = {
	root: {
		// display: 'grid',
		// gridTemplateColumns: '1fr 80px 20px',
		// gridTemplateRows: '32px 16px',
		padding: '10px 20px',
		cursor: 'pointer',
		borderBottom: 'solid #00000017 1px',
		margin: '0.5em',
		// backgroundColor: this.bgColor,
		// flexGrow: 1,
		width: '97.5vw',
	},
	current: {
		// backgroundColor: '#0000002b',
		// outlineColor: "#82A98B"
		border: "3px solid #82A98B"

	},
	primaryField: {
		fontSize: '1.7em',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	dot: {
		marginRight: '1em',
		height: "0.5em",
		width: "0.5em",
		backgroundColor: "#bbb",
		borderRadius: "50%",
		display: "inline-block"
	},
	time: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	duration: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	icon: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	ringTime: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	lastMessage: {
		fontSize: '15px',
	},
	customerOffline: {
		// gridColumn: '2 / -1',
		color: 'red',
		fontSize: '12px',
	},
	arrow: {
		justifyContent: 'flex-end',
		display: 'flex',
		alignItem: 'center',
	},
	arrowWrapper: {
		marginTop: 'auto',
		marginBottom: 'auto',
	},
};

const dateFormatter = new Intl.DateTimeFormat('en-US', {
	hour: 'numeric',
	minute: 'numeric',
});

class CustomerLine extends React.Component {
	state = {
		minutes: 0,
		hours: 0,
		color: '',
		isOnline: 'true',
		lastChat: ''
	};

	bgColor = '#F6F6F6';
	dotColor = '#82A98B'
	connectionStatus = 'Connected'
	timer = () => {
		setInterval(
			function () {
				this.setState({ isOnline: this.props.ping.isOnline })
				this.setState({ color: this.props.ping.color });
				this.setState({ minutes: +this.state.minutes + 1 });
				if (this.state.minutes === 60) {
					this.setState({ minutes: 0, hours: this.state.hours + 1 });
				}
			}.bind(this),
			60 * 1000
		); // 60 * 1000 milsec
	};

	componentDidMount() {
		if (this.duration) {
			this.setState({ hours: this.duration.hours });
			this.setState({
				minutes: this.duration.minutes.toString().split('.')[0],
			});
			this.setState({ color: this.props.ping.color });
		}
		this.setState({ lastChat: dateFormatter.format(new Date(this.props.ping.dateTimeStarted)) })
		//this.setState(props => ({ lastChat: dateFormatter.format(new Date(this.props.ping.dateTimeStarted))}))
		this.timer();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.chatList.length > 0) {
			let chat = typeof (this.chatList[this.chatList.length - 1]?.dateTimeSent) == 'string' ?
				DateTime.fromISO(this.chatList[this.chatList.length - 1]?.dateTimeSent.toString().split('T')[1]).toFormat("h:mm a")
				: DateTime.fromISO(this.chatList[this.chatList.length - 1]?.dateTimeSent.toString().split(" ")[4]).toFormat("h:mm a")
			if (this.props.ping && (this.state.lastChat !== chat)) {
				this.setState({ lastChat: chat })
			}
		}
	}

	componentWillUnmount() {
		clearInterval(this.timer);
	}

	findPrimary = (fields) => {
		return fields.find((field) => field.field.isPrimary);
	};


	arrayLast = (arr) => {
		return arr[arr.length - 1];
	};
	chatList = this.props?.ping.chatMessageList
	// lastChat = DateTime.fromISO(this.chatList[this.chatList.length - 1].dateTimeSent).toLocaleString(DateTime.TIME_SIMPLE)

	startTime = (() => {
		let date = DateTime.fromISO(this.props.ping.dateTimeStarted);
		if (date.invalid) {
			date = DateTime.fromMillis(
				new Date(this.props.ping.dateTimeStarted).getTime()
			);
		}
		return date;
	})();
	currentTime = DateTime.now();
	duration = this.currentTime.diff(this.startTime, ['hours', 'minutes']);

	render() {
		const { classes } = this.props;
		if (this.state.color === 'red') this.bgColor = '#FEE6E6';
		if (this.state.color === 'orange') this.bgColor = '#FFF7DC';
		this.dotColor = this.state.isOnline ? '#82A98B' : '#ffd544'
		this.connectionStatus = this.state.isOnline ? 'Connected' : 'Not Connected'

		return (
			<Grid
				container
				// spacing={1}
				className={`${classes.root} ${this.props.current && classes.current}`}
				onClick={this.props.openDetails}
				style={{ backgroundColor: this.bgColor, marignTop: '2em' }}
			>
				<Grid item xs={3}>
					<Typography variant="subtitle2">
						{this.findPrimary(this.props.ping.fields)?.field.name}: <br />
					</Typography>
					<Typography component={'div'}>
						<Box variant="h5" fontWeight={700} className={classes.primaryField}>
							{this.findPrimary(this.props.ping.fields)?.value}
							<Tooltip title={this.connectionStatus}>
								<div className={classes.dot} style={{ backgroundColor: this.dotColor }} />
							</Tooltip>
						</Box>
					</Typography>
				</Grid>
				<Grid item xs={2} className={classes.time}>
					<Typography variant="subtitle2">
						Last Chat:{' '}
						{this.state.lastChat}
					</Typography>
					<Typography variant="subtitle1" className={classes.lastMessage}>
						{this.arrayLast(this.props.ping.chatMessageList)?.message}
					</Typography>
				</Grid>
				<Grid item xs={2} className={classes.duration}>
					<Typography variant="subtitle2">
						Duration:
						{/* {pings.0.chatMessageList.0.dateTimeStarted} */}
					</Typography>
					{this.duration.values && (
						<Typography variant="subtitle1" style={{ whiteSpace: 'nowrap' }}>
							{this.duration.hours > 0 && (
								<span>{this.state.hours} Hours </span>
							)}{' '}
							{
								<span>
									{this.state.minutes} Minutes{' '}
								</span>
							}
						</Typography>
					)}
				</Grid>
				<Grid item xs={1} className={classes.ringTime}>
					{/* <NotificationsActiveIcon
					style={{ color: this.props.ping.color }}
					className={classes.icon}
				/> */}
					<Typography variant="subtitle2">Ring Time:</Typography>
					<br />
					<Typography variant="subtitle1">
						{this.startTime.toLocaleString(DateTime.TIME_SIMPLE)}
					</Typography>
				</Grid>
				<Grid item xs={4} className={classes.arrow}>
					<div className={classes.arrowWrapper}>
						<svg
							width="25"
							height="80%"
							viewBox="0 0 16 26"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M15.1328 12.1289C15.6602 12.6562 15.6602 13.5352 15.1328 14.0625L3.76563 25.4883C3.17969 26.0156 2.30078 26.0156 1.77344 25.4883L0.425782 24.1406C-0.101561 23.6133 -0.101562 22.7344 0.425782 22.1484L9.44922 13.125L0.425781 4.04297C-0.101562 3.45703 -0.101562 2.57812 0.425781 2.05078L1.77344 0.703124C2.30078 0.175781 3.17969 0.175781 3.76563 0.703124L15.1328 12.1289Z"
								fill="#ABABAB"
							/>
						</svg>
					</div>
				</Grid>
				{/* {!this.props.ping.isOnline && (
					<span className={classes.customerOffline}>Customer offline</span>
				)} */}
			</Grid>
		);
	}
}

export default withStyles(styles)(CustomerLine);

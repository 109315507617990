import React from 'react';
import { TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	root: {
		display: 'grid',
		gridTemplateColumns: 'repeat(2, 1fr)',
		gridGap: '10px',
	},
	fullWith: {
		gridColumn: '1 / -1',
	},
});

export default function UserComponent({ user, onChange, errors }) {
	const classes = useStyles();

	const handleChange = (e) => {
		console.log(e);

		let { name, value } = e.target;
		user = { ...user, [name]: value || null };
		onChange(user);
	};

	return (
		<div className={classes.root}>
			<TextField
				size="small"
				variant="outlined"
				name="email"
				label="Email"
				type="email"
				value={user.email || ''}
				required
				onChange={handleChange}
				className={classes.fullWith}
			/>
			<TextField
				size="small"
				variant="outlined"
				name="phoneNumber"
				label="Phone Number"
				type="tel"
				inputMode="tel"
				value={user.phoneNumber || ''}
				onChange={handleChange}
				className={classes.fullWith}
			/>
			<Typography
				variant="subtitle2"
				component="h4"
				className={classes.fullWith}
			>
				Store dashboard login
			</Typography>
			<TextField
				size="small"
				variant="outlined"
				name="username"
				error={'userName' in errors}
				helperText={errors.userName}
				label="Username"
				value={user.username || ''}
				required
				onChange={handleChange}
				inputProps={{
					pattern: '[A-Za-z0-9]{5,}',
					title: 'Minimum 5 characters, letters or numbers only',
				}}
			/>
			<TextField
				size="small"
				variant="outlined"
				name="password"
				label="Password"
				type="password"
				value={user.password || ''}
				required
				onChange={handleChange}
				autoComplete="new-password"
				inputProps={{
					pattern: '(?=.*[a-z])(?=.*[A-Z]).*',
					minLength: '6',
					title:
						'Minimum 5 characters, at least one uppercase and one lowercase',
				}}
			/>
		</div>
	);
}

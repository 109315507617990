import React from 'react';
import { TextField, Checkbox, IconButton, Radio, Tooltip } from '@material-ui/core';
import { Delete as DeleteIcon } from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	root: {
		display: "contents"
	}
});

export default function Field({field, onChange, onRemove, setAsPrimary}) {
	const classes = useStyles();

	const handleChange = e => {
		let { name, value, type } = e.target;
		if(type === "checkbox") value = e.target.checked;
		field = { ...field, [name]: value };
		onChange(field);
	}

	return (
		<div className={classes.root}>
			<TextField size="small" variant="outlined" onChange={handleChange} name="name" value={field.name} required label="Field Name" type="text" />
			<Tooltip title="Primary / Main field">
				<Radio checked={field.isPrimary} onChange={setAsPrimary} />
			</Tooltip>
			<Tooltip title="Required">
				<Checkbox onChange={handleChange} name="required" checked={field.required} />
			</Tooltip>
			<IconButton onClick={onRemove}>
				<DeleteIcon />
			</IconButton>
		</div>
	);
}

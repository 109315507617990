import { pingService } from './PingService';
import EventTarget from '@ungap/event-target';

class SoundService extends EventTarget {
	audio = new Audio('/bell.mp3');
	_hasListener = false;

	constructor(...args) {
		super(...args);
		this.silentPlay();
		pingService.addEventListener('message', (e) => {
			if (e.detail.message.sentBy === 'customer') this.play();
		});
		pingService.addEventListener('NewPing', this.play);
	}

	silentPlay = () => {
		this.audio.volume = 0;
		this.play();
		setTimeout(() => {
			this.audio.pause();
			this.audio.currentTime = 0;
			this.audio.volume = 1;
		}, 1000);
	};

	silentPlayOnClick() {
		if (!this._hasListener) {
			document.addEventListener('click', this.silentPlay);
			document.addEventListener('touchstart', this.silentPlay); // instead of click event because of safari IOS bug
			this._hasListener = true;
		}
	}

	removeSilentPlayOnClick() {
		if (this._hasListener) {
			document.removeEventListener('click', this.silentPlay);
			document.removeEventListener('touchstart', this.silentPlay);
			this._hasListener = false;
		}
	}

	play = () => {
		this.audio
			.play()
			.then(() => {
				console.log('play: success');
				this.triggerSoundEvent(true);
				this.removeSilentPlayOnClick();
			})
			.catch((err) => {
				console.log('play: fail');
				this.triggerSoundEvent(false);
				this.silentPlayOnClick();
			});
	};

	triggerSoundEvent(on) {
		this.dispatchEvent(new CustomEvent('sound', { detail: { soundOn: on } }));
	}
}
export let soundService = new SoundService();

import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Paper,
  TableSortLabel,
  TablePagination,
  Box,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { apiService } from "../../services/ApiService";
import { visuallyHidden } from "@mui/utils";

const useStyles = makeStyles({
  card: {
    boxShadow: "1px 2px 25px 5px rgb(0 0 0 / 20%) !important",
  },
  header: {
    textAlign: "center",
  },
  loaderWrapper: {
    height: "var(--fullvh)",
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    width: "35px",
  },
  inactive: {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
  tall: {
    lineHeight: "2.5 !important",
  },
});


const DataTable = ({ columnSettings, dataURL, handleRowClick }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(columnSettings[0].id);
  const [orderType, setOrderType] = useState('string');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const classes = useStyles();

  const getTableData = () => {
    setIsLoading(true);
    apiService
      .get(dataURL)
      .then((res) => {
        setData(res);
        console.log(res)
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error(e);
      });
  };

  useEffect(() => {
    getTableData();
    if (location.state && location.state.page) setPage(location.state.page);
  }, []);

  const location = useLocation();

  const handleSort = (event, property, type) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setOrderType(type);
  };

  function descendingComparator(a, b, orderBy, orderType = 'string') {
    if (orderType === 'string') {
      return a[orderBy] ? b[orderBy] ? b[orderBy].localeCompare(a[orderBy]) : 1 : -1;
    }

    if (orderType === 'date') {
      let date1 = new Date(a[orderBy]);
      let date2 = new Date(b[orderBy]);
      if (date2 < date1) {
        return -1;
      }
      if (date2 > date1) {
        return 1;
      }
      return 0;
    }

    if (orderType === 'numeric' || orderType === 'boolean') {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }
  }

  function getComparator(order, orderBy, orderType) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy, orderType)
      : (a, b) => -descendingComparator(a, b, orderBy, orderType);
  }

  function formatData(row, data, type, format) {
    if (format)
      return format(row, data);

    switch (type) {
      case 'date':
        if (row[data] === null)
          return;
        else
          return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
          }).format(new Date(row[data]));
      case 'boolean':
        return row[data].toString();
      default:
        return row[data];
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  return (
    <>
      {isLoading ? (
        <div className={classes.loaderWrapper}>
          <CircularProgress />
        </div>
      ) : (

        <TableContainer component={Paper} elevation={2}>
          <TableContainer sx={{ overflowY: 'auto', maxHeight: '78vh' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow >
                  {columnSettings.map((col) => (
                    <TableCell
                      key={col.id}
                      align={col.numeric ? "right" : "left"}
                      sortDirection={orderBy === col.data ? order : false}
                      colSpan={col.colSpan ?? 1}
                    >
                      {col.sort ?
                        <TableSortLabel
                          active={orderBy === col.data}
                          direction={orderBy === col.data ? order : "asc"}
                          onClick={(e) => handleSort(e, col.data, col.type)}
                        >
                          {col.label}
                          {orderBy === col.data ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                        : col.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  .sort(getComparator(order, orderBy, orderType))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        //hover={row.isActive}
                        //onClick={() => handleRowClick(row.id)}
                        //className={row.isActive ? "" : classes.inactive}
                        key={index}
                      >
                        {columnSettings.map((col, index) => {
                          return <TableCell key={col.id + index} className={classes.tall}>{formatData(row, col.data, col.type, col.format)}</TableCell>
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton={true}
            showLastButton={true}
            style={{ paddingTop: "15px" }}
          />
        </TableContainer>

      )}
    </>
  );
};

export default DataTable;

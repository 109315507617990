import React, { useState } from "react";
import DataTable from "./DataTable";
import { Card, CardHeader, CardContent, Button } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "../Dashboard/Drawer";
import ChatHistory from "./ChatHistory";

let drawerWidth = '36vw';
const useStyles = makeStyles({
    loaderWrapper: {
        height: "var(--fullvh)",
        display: "grid",
        justifyContent: "center",
        alignItems: "center",
    },
    header: {
        paddingBottom: "0",
        paddingTop: "12px",
    },
    drawerSideOpen: {
        width: `calc(100vw - ${drawerWidth})`,
    },
});

const BellReport = () => {
    const [currentPingIndex, setCurrentPingIndex] = useState(null);
    const classes = useStyles();

    function renderButton(row) {
        return <Button color='primary' onClick={() => chatHistoryOnClick(row['id'])}
            disabled={row['chatCount'] === 0}>
            Chat History
        </Button>
    }

    const chatHistoryOnClick = (id) => {
        if (currentPingIndex !== null) {
            setCurrentPingIndex(null);
            setTimeout(() => setCurrentPingIndex(id), 450);
        }
        else {
            setCurrentPingIndex(id);
        }
    }

    const columnSettings = [
        {
            id: 'started',
            label: 'Started',
            data: 'dateTimeStarted',
            type: 'date',
            sort: true,
        },
        {
            id: 'ended',
            label: 'Ended',
            data: 'dateTimeEnded',
            type: 'date',
            sort: true,
        },
        {
            id: 'duration',
            label: 'Duration',
            data: 'duration',
            type: 'numeric',
            format: (row, seconds) => {
                let min = Math.floor(row[seconds] / 60);
                let sec = Math.round(row[seconds] % 60);
                return min + ' min ' + sec + ' sec';
            },
            sort: true,
        },
        {
            id: 'responded',
            label: 'Responded',
            data: 'responded',
            type: 'boolean',
            sort: true,
        },
        {
            id: 'rating',
            label: 'Rating',
            data: 'storeRatings',
            type: 'numeric',
            format: (row, rating) => {
                let returnStr = '';
                if (row[rating]) {
                    return [...Array(row[rating])].map(() => <span>&#9733;</span>);
                }
                return returnStr;
            },
            sort: true,
        },
        {
            id: 'feedback',
            label: 'Feedback',
            data: 'feedback',
            sort: true,
        },
        {
            id: 'endedAs',
            label: 'Ended As',
            data: 'endedAs',
            sort: true,
        },
        {
            id: 'history',
            data: 'id',
            sort: false,
            format: (row, data) => renderButton(row),
        },
    ]

    const onClose = () => setCurrentPingIndex(null);

    return (<>
        <div className={currentPingIndex !== null ? classes.drawerSideOpen : ''}>
            <Card elevation={0}>
                <CardHeader title="Ping Report" className={classes.header}
                    titleTypographyProps={{ variant: "h5", align: "center" }} />
                <CardContent>
                    <DataTable
                        dataURL={`store/pingReport`}
                        columnSettings={columnSettings} />
                </CardContent>
            </Card >
        </div>
        <Drawer pingId={currentPingIndex}>
            {currentPingIndex !== null &&
                <ChatHistory id={currentPingIndex} onClose={onClose} />}
        </Drawer>
    </>
    );
}

export default BellReport;

import { toast } from 'react-toastify';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import {
	apiService,
	objectToSearchStringWithoutEncoding,
	searchParamsToObjectWithoutUrlEncoding,
} from '../services/ApiService';
import { useState } from 'react';
import { useContext } from 'react';
import { StoreContext } from '../context/store';
import { debug } from 'logrocket';
// import { storeService } from "../services/StoreService";

const styles = {
	root: {
		height: '100%',
	},
	logo: {
		height: '100px',
		margin: '0 auto 20px auto',
	},
	form: {
		height: '100%',
		alignContent: 'center',
		display: 'grid',
		rowGap: '30px',
		margin: '0 auto',
		maxWidth: '400px',
	},
	resetPasswordLink: {
		justifySelf: 'end',
	},
	loaderWrapper: {
		height: 'var(--fullvh)',
		display: 'grid',
		justifyContent: 'center',
		alignItems: 'center',
	},
};
function Login(props) {
	const [state, setState] = useState({
		isLoading: false,
	});
	const { getOpenChats, store } = useContext(StoreContext);
	const searchParams = new URLSearchParams(window.location.search);
	console.log(window.location.search);
	const email = searchParams.get('email');
	const password = searchParams.get('password');
	
	const onSubmit = async (e) => {
		try {
			e.preventDefault();
			setState({ isLoading: true });
			let loginInfo = formToObject(e.target);
			loginInfo.recaptchaToken = await getRecaptchaToken();
			console.log(loginInfo);
			debugger
			apiService
			.post(`account/login`, loginInfo)
			.then(user => {
				console.log(user);
				debugger
				if (!user.role.includes('Store')) {
					throw 'Unauthorized';
				}
				getOpenChats();
			})
			.catch(e => {
				debugger
				setState({ isLoading: false });
				console.error(e);
					toast.error('Invalid Credentials');
				});
		} catch (err) {
			setState({ isLoading: false });
			console.error(err);
			toast.error('Invalid Credentials');
		}
	};

	function formToObject(form) {
		return Object.fromEntries(new FormData(form));
	}

	async function getRecaptchaToken() {
		return new Promise((resolve, reject) => {
			window.grecaptcha.ready(() => {
				window.grecaptcha
					.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY)
					.then(resolve);
			});
		});
	}

	useEffect(() => {
		if (store) {
			const params = searchParamsToObjectWithoutUrlEncoding();
			const next = params.next;
			delete params.next;
			const url = `${next || '/dashboard'
				}?${objectToSearchStringWithoutEncoding(params)}`;
			props.history.push(url);
		}
	}, [store]);

	function renderForm(classes) {
		return (
			<>
				<style>{`
					.grecaptcha-badge {
						visibility: visible;
					}
				`}</style>
				<form className={classes.form} onSubmit={onSubmit}>
					<img className={classes.logo} src="/curbsideBell-logo.png" alt="" />
					{/* <div href="https://www.havajavaonline.com/" className={classes.storeLink}>
						<h1 className={classes.storeName}>{storeService.store.name}</h1>
					</div> */}

					<TextField
						defaultValue={email || ''}
						name="email"
						required
						type=""
						label="Email"
						variant="outlined"
					/>
					<TextField
						defaultValue={password || ''}
						name="password"
						required
						label="Password"
						variant="outlined"
						type="password"
					/>
					<Button type="submit" variant="contained" color="primary">
						Login
					</Button>
					<Link
						className={classes.resetPasswordLink}
						to="/get-reset-password-email"
					>
						Forgot password
					</Link>
				</form>
			</>
		);
	}

	function renderLoader(classes) {
		return (
			<div className={classes.loaderWrapper}>
				<CircularProgress />
			</div>
		);
	}

	const classes = props.classes;
	return (
		<div className={classes.root}>
			{state.isLoading ? renderLoader(classes) : renderForm(classes)}
		</div>
	);
}

export default withStyles(styles)(Login);

import React from 'react';
import { ChatItem } from './ChatItem';
import { withStyles } from '@material-ui/core/styles';
import { createRef } from 'react';

const styles = {
	cont: {
		minHeight: 'calc(var(--fullvh) - 300px)',
		height: 'fit-content',
		display: 'grid',
		alignContent: 'end',
	},
	root: {
		height: 'auto',
		display: 'grid',
		overflow: 'auto',
	},
};

class ChatUI extends React.Component {
	divRef = createRef();
	componentDidMount() {
		this.divRef.current.scrollTop = this.divRef.current.scrollHeight;
	}
	componentDidUpdate(prevProps) {
		const lengthDiff =
			this.props.messageList.length - prevProps.messageList.length;
		if (lengthDiff > 0) {
			let scrollBottomOffset = 0;
			const div = this.divRef.current;
			for (let i = lengthDiff; i > 0; i--) {
				scrollBottomOffset +=
					div.children[div.children.length - i].offsetHeight + 20; //20 for margin
			}
			//we only want to scroll down if he didn't manually scroll up to see some old messages, but a new messages came in
			if (
				div.scrollHeight - (div.scrollTop + div.clientHeight) ===
				scrollBottomOffset
			) {
				div.scrollTop = div.scrollHeight;
			}
		}
	}
	render() {
		const classes = this.props.classes;
		return (
			<div className={classes.cont}>
				<div className={classes.root} ref={this.divRef}>
					{this.props.messageList.map((item, i) => (
						<ChatItem key={i} chatMessage={item} />
					))}
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(ChatUI);

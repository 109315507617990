import React, { useState, useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Drawer, { theme } from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import { v4 as uuid } from 'uuid';
import CustomerLine from './CustomerLine';
import PingDetails from './PingDetails';
import { pingService } from '../../services/PingService';
import { soundService } from '../../services/SoundService';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { StoreContext } from '../../context/store';
import { CircularProgress } from "@material-ui/core";

let drawerWidth = 300;

const styles = {
	root: {},
	pingList: {},
	noPingsText: {
		textAlign: 'center',
		fontSize: '20px',
		marginTop: '40px',
	},
	drawerSideOpen: {
		width: `calc(100vw - ${drawerWidth}px)`,
	},
	drawer: {
		width: '35vw',
		// width: drawerWidth,
		transition: 'width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;',
		// transition: theme.transitions.create(['margin', 'width'], {
		// 	easing: theme.transitions.easing.easeOut,
		// 	duration: theme.transitions.duration.enteringScreen,
		// }),
	},
	closeDrawerButtonWrapper: {
		display: 'grid',
		justifyContent: 'end',
		height: '50px',
	},
	loaderWrapper: {
		height: "var(--fullvh)",
		display: "grid",
		justifyContent: "center",
		alignItems: "center",
	},
};

let colorsDone = false;

function Dashboard(props) {
	const [state, setState] = useState({
		drawerOpen: false,
		currentPingIndex: null,
		soundOn: true,
		reconnecting: false
	});

	const { store, setStore } = useContext(StoreContext);

	useEffect(() => {
		pingService.addEventListener('message', receiveMessage);
		pingService.addEventListener('NewPing', newPing);
		pingService.addEventListener('close', pingClosed);
		soundService.addEventListener('sound', handleSoundChange);
		pingService.addEventListener('customer-offline', (e) =>
			customerOnlineStatusChange(e.detail.pingId, false)
		);
		pingService.addEventListener('customer-online', (e) =>
			customerOnlineStatusChange(e.detail.pingId, true)
		);
		pingService.addEventListener('connect', () => setState({ ...state, reconnecting: true }))
		pingService.addEventListener('reconnected', () => setState({ ...state, reconnecting: false }))
		pingService.startSocket();
	}, []);

	function customerOnlineStatusChange(pingId, online) {
		setStore((prevState) => {
			let newState = JSON.parse(JSON.stringify(prevState));
			let pingIndex = newState.pings.findIndex((p) => pingId === p.id);
			if (pingIndex !== -1) {
				newState.pings[pingIndex].isOnline = online;
				return newState;
			} else {
				// ping not active anymore
				return prevState;
			}
		});
	}

	useEffect(() => {
		if (store && !colorsDone) {
			colorsDone = true;
			setStore({
				...store,
				pings: store.pings.map((ping) => {
					const tempPing = { ...ping };
					tempPing.color = 'green';
					tempPing.isOnline = Boolean(ping.socketId);
					setupColorChanges(ping.id, new Date(ping.dateTimeStarted));
					return tempPing;
				}),
			});
		}
	}, [store]);

	const newPing = (e) => {
		let ping = e.detail.ping;
		ping.color = 'green';
		setStore((store) => {
			setupColorChanges(ping.id, new Date(ping.dateTimeStarted), store);
			return {
				...store,
				pings: [...store.pings, ping],
			};
		});
	};

	const pingClosed = (e) => {
		setStore((store) => {
			let newPings = [...store.pings];

			var removedPingIndex = newPings.findIndex(
				(p) => p.id === e.detail.ping.id
			);

			let currentPingIndex = sessionStorage.getItem('currentPingIndex');
			if (Number(currentPingIndex) === removedPingIndex) {
				// closing sidebar if current closed
				sessionStorage.removeItem('currentPingIndex');
				setState({ ...state, currentPingIndex: null });
			}

			if (removedPingIndex !== -1) {
				newPings.splice(removedPingIndex, 1);
			}

			return {
				...store,
				pings: newPings,
			};
		});
	};

	function setupColorChanges(pingId, date, passedStore) {
		const localStore = passedStore ?? store;
		let timeToOrange = localStore.storeConfig.secondsToOrange * 1000;
		let timeToRed = localStore.storeConfig.secondsToRed * 1000;

		const pingTimeStamp = date.getTime(),
			currentTimeStamp = Date.now();
		const orangeTimeout = timeToOrange - (currentTimeStamp - pingTimeStamp),
			redTimeout = timeToRed - (currentTimeStamp - pingTimeStamp);

		setTimeout(() => {
			changePingToColor(pingId, 'orange');
		}, orangeTimeout);
		setTimeout(() => {
			changePingToColor(pingId, 'red');
		}, redTimeout);
	}

	function changePingToColor(pingId, color) {
		setStore((prevStore) => {
			let newStore = JSON.parse(JSON.stringify(prevStore));
			let ping = newStore.pings.find((p) => p.id === pingId);
			if (!ping) return prevStore; // ping is already done
			ping.color = color;
			return newStore;
		});
	}

	const receiveMessage = (e) => {
		let { message } = e.detail;
		message.dateTimeSent = new Date(e.detail.message.dateTimeSent);
		setStore((prevStore) => {
			let newStore = JSON.parse(JSON.stringify(prevStore));
			let pingIndex = newStore.pings.findIndex((p) => message.pingId === p.id);
			newStore.pings[pingIndex].chatMessageList.push(message);
			return newStore;
		});
	};

	const openDetails = (i) => {
		//if (state.currentPingIndex !== null) {
		if (sessionStorage.getItem('currentPingIndex') !== null) {
			sessionStorage.setItem('currentPingIndex', i);
			setState({ ...state, currentPingIndex: null });
			setTimeout(() => setState({ ...state, currentPingIndex: i }), 500);

		}
		else {
			sessionStorage.setItem('currentPingIndex', i);
			setState({ ...state, currentPingIndex: i });
		}
	}

	const closeDetails = () => {
		sessionStorage.removeItem('currentPingIndex');
		setState({ ...state, currentPingIndex: null });
	};

	const handleSoundChange = (e) => {
		setState({ ...state, soundOn: e.detail.soundOn });
	};

	const classes = props.classes;

	return (
		<>
			{state.reconnecting ?
				<div className={classes.loaderWrapper}>
					<CircularProgress />
				</div>
				: <div className={classes.root}>
					<div
						className={
							//state.currentPingIndex !== null ? classes.drawerSideOpen : ''
							sessionStorage.getItem("currentPingIndex") !== null ? classes.drawerSideOpen : ''
						}
					>
						<div className={classes.pingList}>
							{store?.pings
								?.map((ping, i) => (
									<CustomerLine
										openDetails={() => openDetails(i)}
										key={uuid()}
										ping={ping}
										//current={state.currentPingIndex === i}
										current={sessionStorage.getItem('currentPingIndex') === i}
									/>
								))
								.reverse()}
							{store?.pings.length === 0 && (
								<p className={classes.noPingsText}>
									There are no active curbside customers
								</p>
							)}
						</div>
					</div>
					<Drawer
						variant="persistent"
						anchor="right"
						//open={state.currentPingIndex !== null}
						open={sessionStorage.getItem('currentPingIndex') !== null}
						classes={{
							paper: classes.drawer,
						}}
					>
						{/* <div className={classes.closeDrawerButtonWrapper}>
					<IconButton onClick={closeDetails} aria-label="close">
						<CloseIcon />
					</IconButton>
				</div> */}
						{store !== undefined && sessionStorage.getItem('currentPingIndex') !== null && (
							//state.currentPingIndex !== null && (
							<PingDetails
								//ping={store?.pings[state.currentPingIndex]}
								ping={store?.pings[sessionStorage.getItem('currentPingIndex')]}
								presetMessages={store?.presetMessages}
								closeDetails={closeDetails}
								allowResponse={store?.storeConfig.allowResponse}
							/>
						)}
					</Drawer>

					{/* <Dialog open={!state.soundOn}>
				<DialogTitle>You need to turn on bell sound</DialogTitle>
				<DialogContent style={{ textAlign: 'center' }}>
					<Button variant="contained" color="primary">
						Turn on
					</Button>
				</DialogContent>
			</Dialog> */}
				</div>
			}
		</>);
}

export default withStyles(styles)(Dashboard);

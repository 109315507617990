import React, { useState } from 'react';
import {
	Button,
	CircularProgress,
	Card,
	CardHeader,
	CardContent,
	Typography,
	TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import StoreComponent from '../Settings/Store/StoreComponent';
import { PresetMessage, Store } from '../../Models/Store.model';
import CreditCardComponent from '../Settings/Store/CreditCard';
import UserComponent from '../Settings/Store/User';
import { apiService } from '../../services/ApiService';
import { toast } from 'react-toastify';
import { Field } from '../../Models/Store.model';
import SignupSuccessDialog from '../Settings/Store/SingupSuccessDialog';
import { trackersService } from '../../services/TrackersService';

const params = new URLSearchParams(window.location.search);

function getDefaultStore() {
	let store = new Store();
	let firstField = new Field();
	firstField.name = 'Order No.';
	firstField.isPrimary = true;
	firstField.required = true;
	store.fields.push(firstField);
	store.presetMessages = getDefaultPresetMessages();
	const openTime = '09:00';
	const closeTime = '17:00';
	store.storeConfig.openMonday = true;
	store.storeConfig.opensAtMonday = openTime;
	store.storeConfig.closesAtMonday = closeTime;
	store.storeConfig.openTuesday = true;
	store.storeConfig.opensAtTuesday = openTime;
	store.storeConfig.closesAtTuesday = closeTime;
	store.storeConfig.openWednesday = true;
	store.storeConfig.opensAtWednesday = openTime;
	store.storeConfig.closesAtWednesday = closeTime;
	store.storeConfig.openThursday = true;
	store.storeConfig.opensAtThursday = openTime;
	store.storeConfig.closesAtThursday = closeTime;
	store.storeConfig.openFriday = true;
	store.storeConfig.opensAtFriday = openTime;
	store.storeConfig.closesAtFriday = '15:00';
	store.storeConfig.secondsToOrange = 120;
	store.storeConfig.secondsToRed = 240;
	store.storeConfig.secondsToRemind = 180;
	store.storeConfig.bellInviteBodyText =
		'Your order is ready, please Ring the bell and we will bring it out to you.';
	store.storeConfig.bellInviteSubject = 'Your order is ready';
	if (params.get('name')) {
		store.name = params.get('name')?.replaceAll(' ', '') || '';
		store.url = params.get('name')?.toLowerCase().replaceAll(' ', '-') || '';
	}
	return store;
}

function getDefaultUser() {
	let user = {};
	if (params.get('phone')) user.phoneNumber = params.get('phone') || undefined;
	if (params.get('email')) user.email = params.get('email') || undefined;
	if (params.get('name')) user.username = params.get('name') || undefined;
	return user;
}

function getDefaultPresetMessages() {
	return [
		'Give us a couple of minutes',
		'Give us a minute',
		'On the way',
		'We are a little behind, we will be out soon',
	].map((m) => {
		const pm = new PresetMessage();
		pm.message = m;
		return pm;
	});
}

function getDefaultCreditCard() {
	let cc = {};
	cc.cardNick = 'Added by CurbsideBell';
	return cc;
}

const useStyles = makeStyles((theme) => ({
	form: {
		display: 'grid',
		gridGap: '20px',
		maxWidth: '500px',
		margin: 'auto',
	},
	nameInput: {
		width: '100%',
		marginBottom: '10px',
	},
	guarantee: {
		marginBottom: '10px',
	},
	termsAndConditionsText: {
		textAlign: 'center',
	},
	termsAndConditionsLink: {
		color: theme.palette.primary.main,
	},
}));

async function getRecaptchaToken() {
	return new Promise((resolve, reject) => {
		window.grecaptcha.ready(() => {
			// @ts-ignore
			window.grecaptcha
				.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY)
				.then(resolve);
		});
	});
}

export default function Signup({ history }) {
	const classes = useStyles();

	const [errors, setErrors] = useState({});
	const [store, setStore] = useState(getDefaultStore);
	const [creditCard, setCreditCard] = useState(getDefaultCreditCard);
	const [user, setUser] = useState(getDefaultUser);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);

	const handleChange = (store) => {
		setStore(store);
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		const body = {
			store,
			creditCard,
			...user,
			recaptchaToken: await getRecaptchaToken(),
		};
		console.log(body);
		apiService
			.post(`account/signup`, body)
			.then((s) => {
				toast.success('Account setup successful!');
				console.log(s);
				setLoading(false);
				setSuccess(true);
				trackersService.trackSignup();
			})
			.catch((e) => {
				console.log(e);
				setLoading(false);
				setErrors(e.errors);
				document
					.getElementsByName(Object.keys(e.errors)[0].toLocaleLowerCase())[0]
					.scrollIntoView({
						block: 'center',
						behavior: 'smooth',
					});
			});
	};

	return (
		<form className={classes.form} onSubmit={onSubmit}>
			<Card>
				<CardHeader title="Store Details"></CardHeader>
				<CardContent>
					<div>
						<TextField
							size="small"
							variant="outlined"
							name="name"
							value={store.name}
							required
							onChange={(e) => handleChange({ ...store, name: e.target.value })}
							label="Business Name"
							type="text"
							className={classes.nameInput}
						/>
						<UserComponent
							user={user}
							errors={errors}
							onChange={(u) => setUser(u)}
						/>
					</div>
				</CardContent>
			</Card>

			<Card>
				<CardHeader title="Billing Details"></CardHeader>
				<CardContent>
					<div>
						<Typography
							className={classes.guarantee}
							variant="subtitle2"
							component="h4"
						>
							$19.99/mo - 50 Days Money back guarantee!
						</Typography>
						<CreditCardComponent
							creditCard={creditCard}
							onChange={(cc) => setCreditCard(cc)}
						/>
					</div>
				</CardContent>
			</Card>

			<StoreComponent
				handleStoreChange={handleChange}
				errors={errors}
				store={store}
			/>

			<Typography className={classes.termsAndConditionsText}>
				By submitting this form, you agree to our{' '}
				<a
					className={classes.termsAndConditionsLink}
					href="https://www.curbsidebell.com/terms"
				>
					terms & conditions
				</a>
			</Typography>

			<Button
				type="submit"
				disabled={loading}
				variant="contained"
				color="primary"
			>
				Save and Start
				{loading && <CircularProgress size={24} />}
			</Button>
			{success && <SignupSuccessDialog store={store} />}
		</form>
	);
}

import React, { useEffect } from 'react';
import { PresetMessage } from '../../../Models/Store.model';
import { Button, IconButton, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles({
	root: {
		display: 'grid',
		gridTemplateColumns: 'auto 50px',
		alignItems: 'center',
	},
	addFieldButton: {
		gridColumn: '1 / -1',
		justifySelf: 'left',
	},
});

export default function PresetMessages({ presetMessages, onChange }) {
	useEffect(() => {
		let el = document.querySelector(`.${classes.root}`);
		el = el?.children[el.children.length - 3]?.querySelector('input');
		if (el) el.focus();
	}, [presetMessages.length]);

	const classes = useStyles();

	const handleChange = (e, presetMessage, i) => {
		let value = e.target.value;
		presetMessage = { ...presetMessage, message: value };
		presetMessages[i] = presetMessage;
		onChange(Array.from(presetMessages));
	};

	const handleRemove = (i) => {
		presetMessages.splice(i, 1);
		onChange(presetMessages);
	};

	const add = () => {
		presetMessages.push(new PresetMessage());
		onChange(presetMessages);
	};

	return (
		<div className={classes.root}>
			{presetMessages.map((m, i) => (
				<React.Fragment key={i}>
					<TextField
						value={m.message}
						variant="outlined"
						size="small"
						label="Message"
						onChange={(e) => handleChange(e, m, i)}
					/>

					<IconButton onClick={() => handleRemove(i)}>
						<DeleteIcon />
					</IconButton>
				</React.Fragment>
			))}

			<Button
				className={classes.addFieldButton}
				variant="outlined"
				color="primary"
				onClick={add}
			>
				Add Message
			</Button>
		</div>
	);
}

import React from 'react';
import {
	createMuiTheme,
	makeStyles,
	ThemeProvider,
} from '@material-ui/core/styles';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom';

import Login from './components/Login';
import Dashboard from './components/Dashboard/Dashboard';
import './services/SoundService';
import GetResetPasswordEmail from './components/GetResetPasswordEmail';
import ResetPasswordWithToken from './components/ResetPasswordWithToken';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StoreComponent from './components/Settings/Store/StoreComponent';
import Signup from './components/Settings/Signup';
import Toolbar from './components/Toolbar';
import StoreState from './context/store';
import VerifyEmail from './components/VerifyEmail';
import BellReport from './components/BellReport/Report';
import ChatHistory from './components/BellReport/ChatHistory';
import DeactivatedPage from './components/Deactivated';

let fullvh = window.innerHeight;
document.documentElement.style.setProperty('--vh', `${fullvh * 0.01}px`);
document.documentElement.style.setProperty('--fullvh', `${fullvh}px`);

const useStyles = makeStyles((theme) => ({
	root: {
		// height: 'var(--fullvh)',
	},
	footer: {
		padding: '6px',
		textAlign: 'center',
	},
	link: {
		color: theme.palette.primary.main,
	},
}));

const theme = createMuiTheme({
	palette: {
		primary: {
			light: '#9bbaa2',
			main: '#82A98B',
			dark: '#5b7661',
			contrastText: '#fff',
		},
		secondary: {
			light: '#ffffff',
			main: '#d6ecda',
			dark: '#a5baa8',
			contrastText: '#000',
		},
	},
	typography: {
		subtitle1: {
			fontSize: '1rem',
		},
		subtitle2: {
			fontSize: '0.75rem',
		},
	},
});

function App() {
	const classes = useStyles();
	return (
		<StoreState>
			<ThemeProvider theme={theme}>
				<div className={classes.root}>
					<Router>
						<Toolbar>
							<Switch>
								<Route exact path="/" render={() => <Redirect to="/login" />} />
								<Route path="/login" component={Login} />
								<Route
									path="/get-reset-password-email"
									component={GetResetPasswordEmail}
								/>
								<Route path="/verify-email" component={VerifyEmail} />
								<Route
									path="/reset-password-with-token"
									component={ResetPasswordWithToken}
								/>
								<Route path="/dashboard" component={Dashboard} />
								<Route path="/signup" component={Signup} />
								<Route path="/settings/:tab?" component={StoreComponent} />
								<Route path="/ping-report" component={BellReport} />
								<Route path="/chat-history/:id" component={ChatHistory} />
								<Route path="/deactivated" component={DeactivatedPage} />
							</Switch>
						</Toolbar>
					</Router>
					<ToastContainer />
				</div>
			</ThemeProvider>
		</StoreState>
	);
}

export default App;

export class Store {
	id = 0;
	name = '';
	url = '';
	logo = '';
	storeWebsiteUrl = undefined;
	address = '';
	storeConfig = new StoreConfig();
	fields = [];
	allowedPingLocationCorners = [];
	presetMessages = [];
}

export class StoreConfig {
	locationRequired = false;
	storeLatitudes = 0;
	storeLongitudes = 0;
	secondsToOrange = 0;
	secondsToRed = 0;
	secondsToRemind = 0;
	openSunday = false;
	opensAtSunday;
	closesAtSunday = undefined;
	openMonday = false;
	opensAtMonday = undefined;
	closesAtMonday = undefined;
	openTuesday = false;
	opensAtTuesday = undefined;
	closesAtTuesday = undefined;
	openWednesday = false;
	opensAtWednesday = undefined;
	closesAtWednesday = undefined;
	openThursday = false;
	opensAtThursday = undefined;
	closesAtThursday = undefined;
	openFriday = false;
	opensAtFriday = undefined;
	closesAtFriday = undefined;
	openSaturday = false;
	opensAtSaturday = undefined;
	closesAtSaturday = undefined;
	bellInviteBodyText = undefined;
	bellInviteSubject = undefined;
	bellInviteReplyTo = undefined;
}

export class Field {
	name = '';
	isPrimary = false;
	required = false;
}

export class AllowedPingLocationCorner {
	sortOrder = 0;
	latitudes = 0;
	longitudes = 0;
}

export class PresetMessage {
	message = '';
}

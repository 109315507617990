import React, { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import { makeStyles } from '@material-ui/core/styles';
import { apiService } from "../../services/ApiService";
import ChatUI from "../Dashboard/ChatUI";
import { CircularProgress, Grid, Typography, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { StoreContext } from '../../context/store';
import { DateTime } from 'luxon';

const useStyles = makeStyles({
    loaderWrapper: {
        height: 'var(--fullvh)',
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
    },
    closeDrawerButtonWrapper: {
        justifyContent: 'flex-end',
        display: 'flex',
        flexDirection: 'row',
    },
    root: {
        padding: '1em',
        height: '100%',
        overflowX: 'hidden'
    },
    primaryField: {
        // fontWeight: 600,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '70%',
    },
    header: {
        // height: '200px',
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        alignItems: 'start',
        // justifyContent: 'space-around',
    },
    startTime: {
        // textAlign: 'right',
    },
    duration: {
        display: 'flex',
        flexDirection: 'column',
    },
});

const ChatHistory = ({ id, onClose }) => {
    //const [chatMessageList, setChatMessageList] = useState([]);
    const [ping, setPing] = useState(null);
    const [duration, setDuration] = useState(null);
    const { store } = useContext(StoreContext);
    const classes = useStyles();

    useEffect(() => {
        // apiService
        //     .get(`store/chats/${id}`)
        //     .then(res => setChatMessageList(res))
        //     .catch(e => toast.error(e));
        apiService
            .get(`store/pings/${id}`)
            .then(res => {
                console.log(res)
                setPing(res);
                let startTime = getDateObject(res.dateTimeStarted);
                let endTime = getDateObject(res.dateTimeEnded ?? DateTime.now());
                let duration = (endTime).diff(startTime, ['hours', 'minutes']);
                setDuration(duration);
            })
            .catch(e => toast.error(e));
    }, []);
    
    
    const getDateObject = (date) => {
        let dateObj = DateTime.fromISO(date);
        if (dateObj.invalid) {
            dateObj = DateTime.fromMillis(
                new Date(date).getTime()
            );
        }
        return dateObj;
    };

    const dateFormatter = new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: 'numeric',
    });

    const findPrimary = (fields) => {
        return fields.find(field => store.fields.find(f => f.id === field.fieldId).isPrimary);
    }

    const findStorePrimary = () => {
        return store.fields.find(f => f.isPrimary);
    }

    return (
        ping === null ?
            <div className={classes.loaderWrapper}>
                <CircularProgress />
            </div>
            : <Grid container className={classes.root}>
                <Grid container className={classes.header}>
                    <Grid item className={classes.primaryField} xs={12}>
                        <Typography variant="h4" style={{ fontWeight: 700 }}>
                            {findPrimary(ping.fields)?.value}
                        </Typography>
                    </Grid>
                    <Grid item className={classes.closeDrawerButtonWrapper} xs={12}>
                        <IconButton onClick={onClose} aria-label="close">
                            <CloseIcon fontSize="large" style={{ fontWeight: 700 }} />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container className="secondRow" spacing={2}>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{findStorePrimary()?.name}:</Typography>
                        <Typography variant="subtitle1">
                            {findPrimary(ping.fields)?.value}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.duration}>
                        <Typography variant="subtitle2">Duration:</Typography>
                        {duration && (
                            <Typography
                                variant="subtitle1"
                                style={{ whiteSpace: 'nowrap' }}
                            >
                                {duration.hours > 0 && (
                                    <span>{duration.hours} Hours </span>
                                )}{' '}
                                {
                                    <span>
                                        {duration.minutes.toString().split('.')[0]} Minutes{' '}
                                    </span>
                                }
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={4} className={classes.startTime}>
                        <Typography variant="subtitle2">Started</Typography>
                        <Typography variant="subtitle1">
                            {dateFormatter.format(new Date(ping.dateTimeStarted))}
                        </Typography>
                    </Grid>
                </Grid>
                <hr
                    style={{ borderTop: '2px solid #bbb', width: '100%', height: '2px' }}
                />
                <Grid container>
                    <Grid item xs={12}>
                        <ChatUI
                            messageList={ping.chatMessageList}
                            style={{ width: '100%' }}
                        />
                    </Grid>
                </Grid>
            </Grid>
    );
}

export default ChatHistory;

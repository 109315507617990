import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import EventTarget from '@ungap/event-target';

class PingService extends EventTarget {
	connection;

	constructor() {
		super();
		this.setupSocket();
	}

	setupSocket() {
		this.connection = new HubConnectionBuilder()
			.withUrl(`${process.env.REACT_APP_API_ENDPOINT}storeHub`)
			.configureLogging(LogLevel.Information)
			.withAutomaticReconnect()
			.build();

		this.connection.onclose(() => {
			// alert('Server has disconnected, please reload your page');
			// window.location.reload();
			this.connection.start();
		});

		this.connection.onreconnecting(() => {
			console.log('connection lost');
			this.dispatchEvent(new CustomEvent('connect', {}));
		});

		this.connection.onreconnected(() => {
			console.log('reconnected');
			this.dispatchEvent(new CustomEvent('reconnected'))
		});

		this.connection.on('message', (message) => {
			console.log(message);
			this.dispatchEvent(new CustomEvent('message', { detail: { message } }));
		});
		this.connection.on('new-ping', (ping) => {
			console.log(ping);
			this.dispatchEvent(new CustomEvent('NewPing', { detail: { ping } }));
		});
		this.connection.on('close', (ping) => {
			console.log(ping);
			this.dispatchEvent(new CustomEvent('close', { detail: { ping } }));
		});
		this.connection.on('customer-offline', (pingId) => {
			console.log(pingId);
			this.dispatchEvent(
				new CustomEvent('customer-offline', { detail: { pingId } })
			);
		});
		this.connection.on('customer-online', (pingId) => {
			console.log(pingId);
			this.dispatchEvent(
				new CustomEvent('customer-online', { detail: { pingId } })
			);
		});
	}

	startSocket() {
		this.connection
			.start()
			.then((e) => {
				console.log('start', e);
			})
			.catch((err) => {
				console.log(err, err.statusCode);
				if (err.statusCode === 401) {
					if (window.location.pathname !== '/login')
						window.location.pathname = '/login';
				}
				setTimeout(() => window.location.reload(), 5000);
			});
	}

	sendMessage(pingId, message) {
		this.connection
			.invoke('message', pingId, message)
			.catch((err) => console.error(err));
	}

	closeError(pingId) {
		this.connection
			.invoke('close', pingId, 'storeClosed')
			.catch((err) => console.error(err));
	}

	closeSuccess(pingId) {
		this.connection
			.invoke('close', pingId, 'success')
			.catch((err) => console.error(err));
	}

	responseChange(pingId, allowed) {
		this.connection
			.invoke('allow-response', pingId, allowed)
			.catch(err => console.error(err));
	}
}
export let pingService = new PingService();

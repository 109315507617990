import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import {
	apiService,
	searchParamsToObjectWithoutUrlEncoding,
} from '../services/ApiService';
import { Link } from 'react-router-dom';

const styles = {
	root: {
		height: '100%',
	},
	logo: {
		height: '100px',
		margin: '0 auto 20px auto',
	},
	form: {
		height: '100%',
		alignContent: 'center',
		display: 'grid',
		rowGap: '30px',
		margin: '0 auto',
		maxWidth: '400px',
	},
	loaderWrapper: {
		height: 'var(--fullvh)',
		display: 'grid',
		justifyContent: 'center',
		alignItems: 'center',
	},
};

class VerifyEmail extends React.Component {
	state = {
		isLoading: true,
		success: false,
	};

	onSubmit = async (e) => {
		try {
			let params = searchParamsToObjectWithoutUrlEncoding();
			await apiService.post(
				`account/confirm-email`,
				{ email: params.email, emailConfirmationToken: params.token }
			);
			this.setState({ isLoading: false, success: true });
		} catch (err) {
			this.setState({ isLoading: false });
			console.error(err);
		}
	};

	componentDidMount() {
		this.onSubmit();
	}

	renderLoader(classes) {
		return (
			<div className={classes.loaderWrapper}>
				<CircularProgress />
			</div>
		);
	}

	renderSuccessMessage(classes) {
		return (
			<div className={classes.loaderWrapper}>
				<span>
					Thanks! We were able to confirm your email{' '}
					<Link to="/login">continue</Link>
				</span>
			</div>
		);
	}

	renderErrorMessage(classes) {
		return (
			<div className={classes.loaderWrapper}>Error verifying your email</div>
		);
	}

	render() {
		const classes = this.props.classes;
		return (
			<div className={classes.root}>
				{this.state.isLoading
					? this.renderLoader(classes)
					: this.state.success
						? this.renderSuccessMessage(classes)
						: this.renderErrorMessage(classes)}
			</div>
		);
	}
}

export default withStyles(styles)(VerifyEmail);

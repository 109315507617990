import React, { useContext, useState } from 'react';
import {
	Avatar,
	Button,
	CircularProgress,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemSecondaryAction,
	ListItemText,
	TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { StoreContext } from '../../../context/store';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import EditIcon from '@material-ui/icons/Edit';
import AddressInput from './AddressInput';

const useStyles = makeStyles({
	root: {
		display: 'grid',
		gridTemplateColumns: 'repeat(20, 1fr)',
		gridGap: '10px',
	},
});

const useAvatarStyles = makeStyles({
	root: {
		borderRadius: 0,
	},
	colorDefault: {
		backgroundColor: '#fff',
	},
});

const breakAtIndexMap = new Map([
	[3, 1],
	[4, 2],
	[5, 1],
	[6, 2],
]);
function parseExpDate(s) {
	let breakAtIndex = breakAtIndexMap.get(s.length);
	let month = s.slice(0, breakAtIndex);
	let year = s.slice(breakAtIndex);
	month = month.padStart(2, '0');
	year = year.padStart(4, '20');
	return { month, year };
}

export default function CreditCardComponent({
	onChange,
	creditCard,
	loading,
	edit,
	setEdit,
}) {
	const { store } = useContext(StoreContext);
	const [minMaxCCLen, setMinMaxCCLen] = useState(19);
	const [minMaxCVCLen, setMinMaxCVCLen] = useState(3);

	const classes = useStyles();
	const avatarClasses = useAvatarStyles();

	const handleChange = (events) => {
		if (!Array.isArray(events)) {
			events = [events];
		}
		const tempCreditCard = { ...creditCard };
		for (const event of events) {
			const { name, value } = event.target;
			tempCreditCard[name] = value;
		}
		onChange(tempCreditCard);
	};

	const handleCVVChange = (event) => {
		let {
			target: { value: v, name },
		} = event;
		v = v.replace(/\D/g, '');
		handleChange({ target: { value: v, name } });
		if (v.length === 3 && !v.startsWith('3')) {
			document.getElementsByName('address')[0].focus();
		} else if (v.length === 4 && v.startsWith('3')) {
			document.getElementsByName('address')[0].focus();
		}
	};

	const handleCreditCardChange = (event) => {
		let {
			target: { value: v, name },
		} = event;
		if (v.startsWith('3')) {
			setMinMaxCCLen(17);
			setMinMaxCVCLen(4);
		}
		v = v.replace(/\D/g, '');
		if (v.length > 12) {
			if (v.startsWith('3')) {
				v = `${v.slice(0, 4)} ${v.slice(4, 10)} ${v.slice(10)}`;
			} else {
				v = `${v.slice(0, 4)} ${v.slice(4, 8)} ${v.slice(8, 12)} ${v.slice(
					12
				)}`;
			}
		} else if (v.length > 8) {
			if (v.startsWith('3')) {
				v = `${v.slice(0, 4)} ${v.slice(4, 10)}${
					v.length > 10 ? ' ' : ''
				}${v.slice(10)}`;
			} else {
				v = `${v.slice(0, 4)} ${v.slice(4, 8)} ${v.slice(8)}`;
			}
		} else if (v.length > 4) {
			v = `${v.slice(0, 4)} ${v.slice(4, 8)}`;
		}

		handleChange({ target: { value: v, name } });
		if (
			(v.length === 19 && !v.startsWith('3')) ||
			(v.length === 17 && v.startsWith('3'))
		) {
			document.getElementsByName('exp')[0].focus();
		}
	};

	const handleExpChange = (event) => {
		let {
			target: { value: v, name },
		} = event;
		v = v.replace(/\D/g, '');
		if (v.length > 2) {
			v = `${v.slice(0, 2)} / ${v.slice(2, 4)}`;
			handleChange({ target: { value: v, name } });
		} else {
			handleChange({ target: { value: v, name } });
		}
		if (v.length === 7) {
			document.getElementsByName('cvv')[0].focus();
		}
	};

	if (store?.cardNumber && !edit) {
		return (
			<List>
				<ListItem>
					<ListItemAvatar>
						{['Visa', 'Amex', 'Discover', 'MasterCard'].includes(
							store.cardBrand
						) ? (
							<Avatar classes={avatarClasses}>
								<img src={`/cc-${store.cardBrand}-brands.svg`} />
							</Avatar>
						) : (
							<Avatar>
								<CreditCardIcon />
							</Avatar>
						)}
					</ListItemAvatar>
					<ListItemText primary={'**** ' + store?.cardNumber} />
					<ListItemSecondaryAction>
						<Button onClick={() => setEdit(!edit)}>
							Change Payment Method
						</Button>
					</ListItemSecondaryAction>
				</ListItem>
			</List>
		);
	}

	return (
		<div>
			<div className={classes.root}>
				<TextField
					size="small"
					variant="outlined"
					name="numbers"
					label="Card Number"
					value={creditCard.numbers}
					required
					minLength={minMaxCCLen}
					maxLength={minMaxCCLen}
					inputProps={{
						minLength: minMaxCCLen,
						maxLength: minMaxCCLen,
					}}
					onChange={handleCreditCardChange}
					style={{ gridColumn: '1 / 10' }}
				/>
				<TextField
					size="small"
					variant="outlined"
					label="Exp"
					required
					value={creditCard.exp}
					name="exp"
					placeholder="MM/YY"
					onChange={handleExpChange}
					style={{ gridColumn: '10 / 17' }}
				/>
				<TextField
					size="small"
					variant="outlined"
					name="cvv"
					label="CVV"
					inputProps={{
						minLength: minMaxCVCLen,
						maxLength: minMaxCVCLen,
					}}
					value={creditCard.cvv}
					required
					onChange={handleCVVChange}
					style={{ gridColumn: '17 / -1' }}
				/>
				<AddressInput
					// value={creditCard.address}
					label="Address"
					required
					name="address"
					onChange={(e) => {
						if (e.addressObj) {
							const { address, city, state, zip } = e.addressObj;
							handleChange([
								{ target: { name: 'address', value: address } },
								{ target: { name: 'city', value: city } },
								{ target: { name: 'state', value: state } },
								{ target: { name: 'zip', value: zip } },
							]);
						} else if (e.address) {
							handleChange({ target: { name: 'address', value: e.address } });
						}
					}}
					style={{ gridColumn: '1 / -1' }}
				/>
				<TextField
					size="small"
					variant="outlined"
					name="city"
					label="City"
					value={creditCard.city}
					required
					InputLabelProps={{ shrink: true }}
					onChange={handleChange}
					style={{ gridColumn: '1 / 10' }}
				/>
				<TextField
					size="small"
					variant="outlined"
					name="state"
					label="State"
					value={creditCard.state}
					required
					InputLabelProps={{ shrink: true }}
					onChange={handleChange}
					style={{ gridColumn: '10 / 14' }}
				/>
				<TextField
					size="small"
					variant="outlined"
					name="zip"
					label="Zip"
					value={creditCard.zip}
					required
					InputLabelProps={{ shrink: true }}
					onChange={handleChange}
					style={{ gridColumn: '14 / -1' }}
				/>
			</div>
			{store?.cardNumber && (
				<div>
					<br />
					<Button onClick={() => setEdit(false)} type="button" fullWidth>
						Cancel
					</Button>
				</div>
			)}
			<div>
				<br />
				<Button
					type="submit"
					disabled={loading}
					variant="contained"
					fullWidth
					color="primary"
				>
					{store?.cardNumber ? 'Update Payment Info' : 'Process Payment'}
					{loading && <CircularProgress size={24} />}
				</Button>
			</div>
		</div>
	);
}

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	TextField,
	RadioGroup,
	FormControlLabel,
	Radio,
	Typography,
	InputAdornment,
	Button,
	CircularProgress,
	Tabs,
	Tab,
	Divider,
	CardHeader,
	CardContent,
	Checkbox,
	Switch
} from '@material-ui/core';
import { apiService } from '../../../services/ApiService';
import { toast } from 'react-toastify';
import DayHours from './DayHours';
import FieldList from './FieldList';
import { Map } from './Map';
import Fs1Input from '../../Fs1Input';
import AddressInput from './AddressInput';
import { cornerService } from '../../../services/CornerService';
import PropTypes from 'prop-types';
import CreditCardComponent from './CreditCard';
import { useContext } from 'react';
import { StoreContext } from '../../../context/store';
import PresetMessages from '../Store/PresetMessages';
import { Prompt } from 'react-router';

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: 800,
		"& .MuiCollapse-wrapperInner > [role='region']": {
			display: 'grid',
			gridGap: '20px',
		},
		'& .MuiCardContent-root': {
			display: 'grid',
			gridGap: '40px',
		},
		'& .MuiCardContent-root > div': {
			display: 'grid',
			gridGap: '10px',
		},
		'& .Mui-checked': {
			color: theme.palette.primary.main,
		},
	},
	hoursWrapper: {
		display: 'grid',
		gridTemplateColumns: '2fr 6fr 1fr 6fr 5fr',
		alignItems: 'center',
	},
	banner: {
		background: '#FABF66',
		height: 40,
		display: 'grid',
		placeItems: 'center',
		'& h1': {
			color: 'white',
			fontSize: 21,
			margin: 0,
		},
	},
}));
const useTabStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		height: '100%',
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		'& .Mui-selected': {
			backgroundColor: theme.palette.secondary.main,
		},
	},
	tabs: {
		borderRight: `1px solid ${theme.palette.divider}`,
	},
	tabCont: {
		marginTop: 36,
		width: '100%',
		maxWidth: 500,
		margin: 'auto',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	indicator: {
		background: theme.palette.primary.dark,
		width: 6,
	},
}));

let polygon = {};
window.polygon = polygon;


const map = ['profile', 'hours', 'location', 'fields', 'messages', 'invite', 'billing'];

export default function StoreComponent({ history, match, location }) {
	const classes = useStyles();
	const tabClasses = useTabStyles();

	const { store, setStore, getOpenChats } = useContext(StoreContext);
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const [creditCard, setCreditCard] = useState({});
	const [allowToLeave, setAllowToLeave] = useState(true);
	const [editCreditCard, setEditCreditCard] = useState(false);

	const handleTabChange = (event, newValue) => {
		history.push('/settings/' + map[newValue]);
	};

	const handleCreditCardChange = (cc) => {
		if (allowToLeave) setAllowToLeave(false);
		setCreditCard(cc);
	};

	const handleStoreChange = (store) => {
		if (allowToLeave) setAllowToLeave(false);
		setStore(store);
	};

	const onSubmit = (e) => {
		e.preventDefault();

		setLoading(true);
		console.log(store);
		apiService
			.put(`store`, store)
			.then((s) => {
				toast.success('Settings updated successfully!');
				console.log(s);
				setStore(store);
				setAllowToLeave(true);
			})
			.catch((e) => {
				console.log(e);
				if (e.errors instanceof Object) {
					setErrors(e.errors);
					const elem = document.getElementsByName(
						Object.keys(e.errors)[0].toLocaleLowerCase()
					)[0];
					if (elem) {
						elem.scrollIntoView({
							block: 'center',
							behavior: 'smooth',
						});
					}
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};
	const onSubmitCreditCard = (e) => {
		e.preventDefault();
		console.log(creditCard);
		setLoading(true);
		apiService
			.post(
				`account/${!store.cardNumber ? 'init-cloudsnob-account' : 'cloudsnob-newcard'
				}`,
				{
					...creditCard,
					expMonth: creditCard.exp.slice(0, 2),
					expYear: creditCard.exp.slice(-2),
					numbers: creditCard.numbers.replace(/\s/g, ''),
					cvv: creditCard.cvv,
					address: creditCard.address,
					city: creditCard.city,
					state: creditCard.state,
					zip: creditCard.zip,
					exp: undefined,
				}
			)
			.then((s) => {
				toast.success('Card successfully saved!');
				console.log(s);
				setAllowToLeave(true);
				setEditCreditCard(false);
				setStore({
					...store,
					cardNumber: creditCard.numbers.slice(-4),
				});
			})
			.catch((e) => {
				console.log(e);
				if (e.errors instanceof Object) {
					setErrors(e.errors);
					const elem = document.getElementsByName(
						Object.keys(e.errors)[0].toLocaleLowerCase()
					)[0];
					if (elem) {
						elem.scrollIntoView({
							block: 'center',
							behavior: 'smooth',
						});
					}
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const findTabIndex = () => {
		const index = map.indexOf(match.params.tab)
		return index > -1 ? index : 0
	}

	useEffect(() => {
		if (!allowToLeave) {
			window.onbeforeunload = function () {
				return false;
			};
		} else {
			window.onbeforeunload = null;
		}
		return () => {
			window.onbeforeunload = null;
		};
	}, [allowToLeave]);

	// if he navigates between tabs, it means that he might be navigating after leaving changes unsaved when prompted to save, so we need to reset the form and allow him to leave from now on
	useEffect(() => {
		if (!allowToLeave) {
			getOpenChats();
			setAllowToLeave(true);
		}
		return () => {
			//if he goes to another page and the componenet unmounts, reset the store data so we are up to date
			getOpenChats();
		};
	}, [location.pathname]);

	const handleRootChange = (e) => {
		let { name, value, type } = e.target;
		if (type === 'number') value = Number(value);
		if (type === 'checkbox') value = e.target.checked;
		const tempStore = { ...store, [name]: value };
		handleStoreChange(tempStore);
	};

	const handleConfigChange = (e) => {
		let { name, value, type } = e.target;
		if (type === 'number') value = Number(value);
		if (type === 'radio') value = e.target.value === 'true';
		if (type === 'checkbox') value = e.target.checked;
		const tempStore = { ...store };
		tempStore.storeConfig = { ...tempStore.storeConfig, [name]: value };
		handleStoreChange(tempStore);
	};

	const handleFieldsChange = (fields) => {
		const tempStore = { ...store, fields };
		handleStoreChange(tempStore);
	};

	const handlePresetMessagesChange = (presetMessages) => {
		const tempStore = { ...store, presetMessages };
		handleStoreChange(tempStore);
	};

	const handleCornersChange = (allowedPingLocationCorners) => {
		store.allowedPingLocationCorners.forEach((each) => {
			const obj = allowedPingLocationCorners.find(
				(e) => e.sortOrder === each.sortOrder
			);
			if (obj) {
				obj.id = each.id;
				obj.storeId = each.storeId;
			}
		});
		const tempStore = { ...store, allowedPingLocationCorners };
		handleStoreChange(tempStore);
	};

	const handleAddressChange = ({ address, latitude, longitude }) => {
		const tempStore = { ...store, address };
		if (latitude !== undefined && longitude !== undefined) {
			tempStore.allowedPingLocationCorners = cornerService.getCornersForLocation(
				latitude,
				longitude
			);
		}
		tempStore.storeConfig = {
			...store.storeConfig,
			storeLatitudes: latitude,
			storeLongitudes: longitude,
		};
		handleStoreChange(tempStore);
	};

	const handleRateStore = async (e) => {
		e.persist();
		await handleConfigChange(e);
		let rateCurbsidebell = document.getElementsByName('rateCurbsidebell')[0];
		if (!e.target.checked && rateCurbsidebell.checked) {
			rateCurbsidebell.click((e) => handleConfigChange(e)); //*bug
			rateCurbsidebell.disabled = true;
		}
		else if (e.target.checked) {
			rateCurbsidebell.disabled = false;
		}
	}

	if (!store)
		return (
			<>
				<CircularProgress size={24} />
			</>
		);

	return (
		<>
			{!store?.cardNumber && (
				<div className={classes.banner}>
					<h1>All features disabled - Please add payment method to access Curbside Bell</h1>
				</div>
			)}
			<div className={tabClasses.root}>
				<Tabs
					orientation="vertical"
					value={findTabIndex()}
					onChange={handleTabChange}
					aria-label="Vertical tabs example"
					classes={{ indicator: tabClasses.indicator }}
					className={classes.tabs}
				>
					<Tab label={'Store Profile'} {...a11yProps(0)} />
					<Tab label={'Hours of Operation'} {...a11yProps(1)} />
					<Tab label={'Location Settings'} {...a11yProps(2)} />
					<Tab label={'Fields'} {...a11yProps(3)} />
					<Tab label={'Preset Messages'} {...a11yProps(4)} />
					<Tab label={'Invite Customers'} {...a11yProps(5)} />
					<Tab label={'Billing'} {...a11yProps(6)} />
				</Tabs>
				<Divider orientation="vertical" flexItem />
				<div className={tabClasses.tabCont}>
					<div className={classes.root}>
						{/* <TextField variant="outlined" name="storeWebsiteUrl" value={store.storeWebsiteUrl} onChange={handleRootChange} label="Store Website" type="url" /> */}
						<TabPanel
							value={findTabIndex()}
							onSubmit={onSubmit}
							loading={loading}
							index={0}
						>
							<div>
								<Typography variant="subtitle2" component="h4">
									Business Name
								</Typography>
								<br />
								<TextField
									size="small"
									variant="outlined"
									name="name"
									value={store.name}
									fullWidth
									onChange={(e) =>
										handleStoreChange({ ...store, name: e.target.value })
									}
									label="Name"
									type="text"
									className={classes.nameInput}
								/>
							</div>
							<div>
								<Typography variant="subtitle2" component="h4">
									Your bell link
								</Typography>
								<br />
								<TextField
									fullWidth
									size="small"
									variant="outlined"
									name="url"
									value={store.url}
									required
									error={'url' in errors}
									helperText={errors.url}
									onChange={handleRootChange}
									label="URL"
									type="text"
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												app.curbsidebell.com/
											</InputAdornment>
										),
									}}
									inputProps={{
										pattern: '[A-Za-z0-9-_]{5,}',
										title: 'Minimum 5 characters. Letters, numbers, -, or _',
									}}
								/>
							</div>
							<div>
								<Typography variant="subtitle2" component="h4">
									Store Logo
								</Typography>
								<br />
								<Fs1Input
									name="logo"
									accept="image/*"
									placeholder="Logo"
									value={store.logo}
									onChange={handleRootChange}
								/>
							</div>
							<div>
								<Checkbox
									checked={!!store.storeConfig.rateStore}
									onChange={handleRateStore}
									name="rateStore" />
								<Typography variant="subtitle2" component="h4" style={{ display: "inline-block" }}>
									Ask customers to rate their experience
								</Typography>
								<Checkbox
									checked={!!store.storeConfig.rateCurbsidebell}
									onChange={handleConfigChange}
									name="rateCurbsidebell" />
								<Typography variant="subtitle2" component="h4" style={{ display: "inline-block" }}>
									Ask customers to rate Curbside Bell
								</Typography>
								{/* <Checkbox
									checked={!!store.storeConfig.shareSocials}
									onChange={handleConfigChange}
									name="shareSocials" />
								<Typography variant="subtitle2" component="h4" style={{ display: "inline-block" }}>
									
								</Typography> */}
							</div>
							<div>
								<FormControlLabel
									control={
										<Switch
											color="primary"
											checked={store.storeConfig.allowResponse}
										/>
									}
									name="allowResponse"
									onChange={handleConfigChange}
								/>
								<Typography variant="subtitle2" component="h4" style={{ display: "inline-block" }}>
									Allow two way chat
								</Typography>
							</div>
						</TabPanel>
						<TabPanel
							index={1}
							value={findTabIndex()}
							onSubmit={onSubmit}
							loading={loading}
						>
							<div>
								<Typography variant="subtitle2" component="h4">
									Available Hours:
								</Typography>
								<div className={classes.hoursWrapper}>
									<DayHours
										day="Sunday"
										isOpen={store.storeConfig.openSunday}
										opensAt={store.storeConfig.opensAtSunday}
										closesAt={store.storeConfig.closesAtSunday}
										onChange={handleConfigChange}
									/>
									<DayHours
										day="Monday"
										isOpen={store.storeConfig.openMonday}
										opensAt={store.storeConfig.opensAtMonday}
										closesAt={store.storeConfig.closesAtMonday}
										onChange={handleConfigChange}
									/>
									<DayHours
										day="Tuesday"
										isOpen={store.storeConfig.openTuesday}
										opensAt={store.storeConfig.opensAtTuesday}
										closesAt={store.storeConfig.closesAtTuesday}
										onChange={handleConfigChange}
									/>
									<DayHours
										day="Wednesday"
										isOpen={store.storeConfig.openWednesday}
										opensAt={store.storeConfig.opensAtWednesday}
										closesAt={store.storeConfig.closesAtWednesday}
										onChange={handleConfigChange}
									/>
									<DayHours
										day="Thursday"
										isOpen={store.storeConfig.openThursday}
										opensAt={store.storeConfig.opensAtThursday}
										closesAt={store.storeConfig.closesAtThursday}
										onChange={handleConfigChange}
									/>
									<DayHours
										day="Friday"
										isOpen={store.storeConfig.openFriday}
										opensAt={store.storeConfig.opensAtFriday}
										closesAt={store.storeConfig.closesAtFriday}
										onChange={handleConfigChange}
									/>
									<DayHours
										day="Saturday"
										isOpen={store.storeConfig.openSaturday}
										opensAt={store.storeConfig.opensAtSaturday}
										closesAt={store.storeConfig.closesAtSaturday}
										onChange={handleConfigChange}
									/>
								</div>
							</div>
						</TabPanel>
						<TabPanel
							value={findTabIndex()}
							index={2}
							showSubmit={Boolean(store.address)}
							onSubmit={onSubmit}
							loading={loading}
						>
							<div
								onKeyPress={(e) => {
									e.key === 'Enter' && e.preventDefault();
								}}
							>
								<Typography variant="subtitle2" component="h4">
									Only allow the bell to ring when the customer is near the
									store
								</Typography>
								<RadioGroup
									name="locationRequired"
									value={store.storeConfig.locationRequired}
									onChange={handleConfigChange}
								>
									<FormControlLabel
										value={false}
										control={<Radio />}
										label="I don't care"
									/>
									<FormControlLabel
										value={true}
										control={<Radio />}
										label="Yes (requires location)"
									/>
								</RadioGroup>
								{store.storeConfig.locationRequired && (
									<div>
										<div>
											<AddressInput
												label="Address"
												address={store.address}
												onChange={handleAddressChange}
												fullWidth
											/>
										</div>
										<br />
										{store.address && (
											<Map
												polygon={polygon}
												onChange={handleCornersChange}
												allowedPingLocationCorners={
													store.allowedPingLocationCorners
												}
												center={{
													latitude: store.storeConfig.storeLatitudes,
													longitude: store.storeConfig.storeLongitudes,
												}}
											/>
										)}
									</div>
								)}
							</div>
						</TabPanel>
						<TabPanel
							value={findTabIndex()}
							onSubmit={onSubmit}
							loading={loading}
							index={3}
						>
							<div>
								<Typography variant="subtitle2" component="h4">
									Add/Edit Fields
								</Typography>
								<FieldList
									fields={store.fields}
									onChange={handleFieldsChange}
								/>
							</div>
						</TabPanel>

						<TabPanel
							value={findTabIndex()}
							onSubmit={onSubmit}
							index={4}
						>
							<div>
								<Typography variant="subtitle2" component="h4">
									Preset Messages to send in chat with customers
								</Typography>
								<CardContent>
									<PresetMessages
										presetMessages={store.presetMessages}
										onChange={handlePresetMessagesChange}
									/>
								</CardContent>
							</div>
						</TabPanel>
						<TabPanel
							value={findTabIndex()}
							onSubmit={onSubmit}
							loading={loading}
							index={5}
						>
							<TextField
								size="small"
								variant="outlined"
								name="bellInviteReplyTo"
								label="ReplyTo email address"
								type="email"
								value={store.storeConfig.bellInviteReplyTo || ''}
								onChange={handleConfigChange}
							/>
							<TextField
								size="small"
								variant="outlined"
								name="bellInviteSubject"
								label="Invite email subject"
								value={store.storeConfig.bellInviteSubject}
								onChange={handleConfigChange}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											{' '}
											- {store.name}{' '}
										</InputAdornment>
									),
								}}
							/>
							<TextField
								multiline
								size="small"
								variant="outlined"
								name="bellInviteBodyText"
								label="Invite email body"
								value={store.storeConfig.bellInviteBodyText}
								onChange={handleConfigChange}
							/>
						</TabPanel>
						<TabPanel
							value={findTabIndex()}
							onSubmit={onSubmitCreditCard}
							index={6}
							showSubmit={false}
						>
							<CreditCardComponent

								edit={editCreditCard}
								setEdit={setEditCreditCard}
								creditCard={creditCard}
								loading={loading}
								onChange={handleCreditCardChange}
							/>
						</TabPanel>
					</div>
				</div>
				<div style={{ height: 24 }}></div>
			</div>
			<Prompt when={!allowToLeave} message="Are you sure you want to leave?" />
		</>
	);
}

function TabPanel(props) {
	const {
		children,
		value,
		index,
		onSubmit,
		loading,
		showSubmit = true,
		...other
	} = props;

	return (
		<form
			onSubmit={onSubmit}
			role="tabpanel"
			hidden={Number(value) !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			<div
				style={{
					display: 'grid',
					gridGap: 48,
				}}
			>
				{children}
			</div>
			{showSubmit && (
				<>
					<br />
					<Button
						type="submit"
						disabled={loading}
						variant="contained"
						fullWidth
						color="primary"
					>
						Save settings
						{loading && <CircularProgress size={24} />}
					</Button>
				</>
			)}
		</form>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
}

import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import MatToolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import {
	Button,
	IconButton,
	Menu,
	MenuItem,
	Typography,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { apiService } from '../services/ApiService';
import Dialog from './inviteCustomer/InviteCustomerDialog';
import { Link, withRouter } from 'react-router-dom';
import { useContext } from 'react';
import { StoreContext } from '../context/store';
import GitInfo from 'react-git-info/macro';

const gitInfo = GitInfo();
console.log('Branch:', gitInfo.branch);
console.log('Commit Date:', gitInfo.commit.date);
console.log('Commit Hash:', gitInfo.commit.hash);
console.log('Commit Message:', gitInfo.commit.message);
console.log('Commit ShortHash:', gitInfo.commit.shortHash);

const useStyles = makeStyles({
	wrapper: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr 1fr',
		width: '100%',
		alignItems: 'center',
	},
	menuButton: {
		color: 'white',
	},
	storeName: {
		textAlign: 'center',
	},
	live: {
		justifySelf: 'right',
		color: '#fff',
	},
	link: {
		color: 'inherit',
		textDecoration: 'none',
	},
	logo: {
		maxHeight: 48,
		verticalAlign: 'middle',
	},
	banner: {
		background: '#FABF66',
		height: 40,
		display: 'grid',
		placeItems: 'center',
		textDecoration: 'none',
		'& h1': {
			color: 'white',
			fontSize: 21,
			margin: 0,
			'&:hover': {
				textDecoration: 'underline',
			},
		},
	},
});

function Toolbar({ children, history, location }) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [menuOpen, setMenuOpen] = useState(false);
	const [storeInfo, setStoreInfo] = useState(null);
	const { store, setStore } = useContext(StoreContext);
	const menuAnchorRef = React.useRef(null);

	useEffect(() => {
		async function getFields() {
			const data = await apiService.get('store');
			setStoreInfo(data);
			console.log(data);
		}
		getFields();
	}, [location.pathname]);

	const logout = () => {
		setStore(null);
		setStoreInfo(null);
		apiService.post('account/logout').then((e) => history.push('/login'));
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const daysLeft = (() => {
		if (!store) return;
		return 0;

		//code for 15 days free trial
		// const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
		// const firstDate = new Date(
		// 	new Date(store.dateCreated).toString().slice(0, 15)
		// );
		// const secondDate = new Date();
		// const diffDays = Math.floor(Math.abs((secondDate - firstDate) / oneDay));
		// const returnVal = diffDays > 15 ? 0 : 15 - diffDays;
		// return returnVal;		
	})();

	useEffect(() => {
		if (store && daysLeft === 0 && !store?.cardNumber) {
			history.push('/settings/billing');
		}
		if (store && !store.isActive) {
			history.push('/deactivated');
		}
	}, [store, location.pathname]);

	return (
		<div style={{ height: '100vh' }}>
			<AppBar position="sticky">
				<MatToolbar>
					<div className={classes.wrapper}>
						<div ref={menuAnchorRef}>
							<Typography variant="h5" className={classes.title}>
								{store ? (
									<IconButton
										className={classes.menuButton}
										onClick={() => setMenuOpen(true)}
									>
										<MenuIcon />
									</IconButton>
								) : null}
								{/* <img src="/curbsideBell-icon.png" className={classes.logo} />{' '}
								Curbside Bell */}
							</Typography>
						</div>
						<Typography
							variant="h5"
							style={{ fontWeight: 600 }}
							className={classes.storeName}
						>
							{store?.name}
						</Typography>
						{store ? (
							<Link to="/dashboard" className={classes.live}>
								<span> {store?.pings.length} Active</span>
							</Link>
						) : null}

						<Menu
							anchorEl={menuAnchorRef.current}
							keepMounted
							open={menuOpen}
							onClose={() => setMenuOpen(false)}
							onClick={() => setMenuOpen(false)}
						>
							<Link className={classes.link} to="/dashboard">
								<MenuItem>Live Feed</MenuItem>
							</Link>
							<Link className={classes.link} to="/ping-report">
								<MenuItem>Ping Report</MenuItem>
							</Link>
							<MenuItem
								variant="outlined"
								color="primary"
								onClick={handleClickOpen}
							>
								Invite Customer
							</MenuItem>
							<Link className={classes.link} to="/settings">
								<MenuItem>Account Settings</MenuItem>
							</Link>
							<MenuItem onClick={logout}>Logout</MenuItem>
						</Menu>
					</div>
					{storeInfo && <Dialog {...{ open, setOpen, storeInfo }} />}
				</MatToolbar>
			</AppBar>
			{/* {store && !store?.cardNumber && (
				<Link to="/settings/billing" className={classes.banner}>
					<div>
						<h1>{daysLeft} Days left to your free trial - Upgrade now</h1>
					</div>
				</Link>
			)} */}
			{children}
		</div>
	);
}

export default withRouter(Toolbar);

class CornerService {
	getCornersForLocation(latitude, longitude) {
		const distance = 0.003;
		let a = {
			sortOrder: 1,
			latitudes: latitude + distance,
			longitudes: longitude + distance,
		};
		let b = {
			sortOrder: 2,
			latitudes: latitude + distance,
			longitudes: longitude - distance,
		};
		let c = {
			sortOrder: 3,
			latitudes: latitude - distance,
			longitudes: longitude - distance,
		};
		let d = {
			sortOrder: 4,
			latitudes: latitude - distance,
			longitudes: longitude + distance,
		};
		return [a, b, c, d];
	}
}
export let cornerService = new CornerService();

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { StoreContext } from '../context/store';
import { CircularProgress } from "@material-ui/core";
import { apiService } from "../services/ApiService";

const useStyles = makeStyles({
    banner: {
        background: '#FABF66',
        height: 40,
        display: 'grid',
        placeItems: 'center',
        '& h1': {
            color: 'white',
            fontSize: 21,
            margin: 0,
        },
    },
});

export default (props) => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        //check if isActive changed
        setIsLoading(true);
        apiService
            .get('store')
            .then(() => setIsLoading(false));
    }, []);

    return (
        isLoading ?
            <CircularProgress />
            : <div className={classes.banner}>
                <h1>Subscription expired - Please contact customer support to restart your service</h1>
            </div>
    )
}
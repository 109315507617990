import React, { useEffect, useRef, useState } from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	root: {},
});

const rand = () => Math.random().toString(); // trying to confuse autofill

export default function AddressInput({
	address,
	label = '',
	onChange,
	types,
	...rest
}) {
	const classes = useStyles();
	const input = useRef(null);
	const [autocomplete, setAutocomplete] = useState(rand); // trying to confuse autofill

	useEffect(() => {
		const options = {};
		if (types) {
			options.types = types;
		}
		const autocomplete = new window.google.maps.places.Autocomplete(
			input.current,
			options
		);
		window.autocomplete = autocomplete;
		setTimeout(() => {
			setAutocomplete(rand);
		}, 1000);

		const listener = () => {
			const place = autocomplete.getPlace();
			if (!place.geometry && place.name !== '') {
				// User entered the name of a Place that was not suggested and
				// pressed the Enter key, or the Place Details request failed.
				window.alert("No details available for input: '" + place.name + "'");
				return;
			}
			console.log(
				place,
				place.geometry.location.lng(),
				place.geometry.location.lat()
			);

			const [address, city, stateZip] = place.formatted_address.split(', ');
			const [state, zip] = stateZip.split(' ');
			const addressObj = {
				address,
				city,
				state,
				zip,
			};
			input.current.value = address;
			onChange({
				address: input.current.value,
				addressObj,
				latitude: place.geometry.location.lat(),
				longitude: place.geometry.location.lng(),
			});
		};
		autocomplete.addListener('place_changed', listener);
		return () => {
			window.google.maps.event.clearInstanceListeners(autocomplete);
		};
	}, [onChange]);

	return (
		<>
			{/* {'\u8204' /* this is a zero with space, trying to confuse autofill  */}
			<TextField
				size="small"
				variant="outlined"
				name={autocomplete}
				defaultValue={address}
				inputRef={input}
				inputProps={{
					placeholder: 'Start typing to get suggestions...',
					autoComplete: autocomplete,
				}}
				label={label}
				{...rest}
			/>
		</>
	);
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const dateFormatter = new Intl.DateTimeFormat('en-US', {
	hour: 'numeric',
	minute: 'numeric',
});

const useStyles = makeStyles({
	root: {
		padding: '6px 6px',
		margin: '10px',
		borderRadius: '5px',
		display: 'grid',
		gridGap: '5px 30px',
		gridTemplateColumns: 'auto auto',
	},
	store: {
		backgroundColor: '#b1eeb75e',
		justifySelf: 'right',
	},
	customer: {
		backgroundColor: 'rgb(0 0 0 / 10%)',
		justifySelf: 'left',
	},
	message: {
		gridColumn: '1 / -1',
	},
	sentBy: {
		fontSize: '11px',
		color: '#00000085',
	},
	time: {
		fontSize: '11px',
		color: '#00000085',
		justifySelf: 'end',
	},
});

export function ChatItem({ chatMessage }) {
	const classes = useStyles();
	var rootClass = `${classes.root} ${
		chatMessage.sentBy === 'store' ? classes.store : classes.customer
	}`;
	return (
		<div className={rootClass}>
			<span className={classes.message} style={{ whiteSpace: 'pre-wrap' }}>{chatMessage.message}</span>
			<span className={classes.sentBy}>
				{chatMessage.sentBy === 'store' ? 'You' : 'Customer'}
			</span>
			<span className={classes.time}>
				{dateFormatter.format(new Date(chatMessage.dateTimeSent))}
			</span>
		</div>
	);
}

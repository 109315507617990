import React, { useState, createContext, useEffect } from 'react';
import { apiService } from '../services/ApiService';

export const StoreContext = createContext();

const StoreState = (props) => {
	useEffect(() => {
		getOpenChats();
	}, []);

	function getOpenChats() {
		apiService
			.get(`store`)
			.then((store) => {
				setStore(store);
			})
			.catch(console.error);
	}
	const [store, setStore] = useState();

	return (
		<StoreContext.Provider
			value={{
				store,
				setStore,
				getOpenChats,
			}}
		>
			{props.children}
		</StoreContext.Provider>
	);
};

export default StoreState;

import React from 'react';
import { Button, Dialog, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
	dialogContent: {
		display: 'grid',
		justifyItems: 'center',
		gridGap: '20px',
		padding: '40px',
	},
	actionsWrapper: {
		marginTop: '20px',
		display: 'flex',
		justifyContent: 'center',
		columnGap: '20px',
	},
});

export default function SignupSuccessDialog({ store }) {
	const classes = useStyles();

	const copyStoreLinkToClipboard = () => {
		navigator.clipboard.writeText('https://app.curbsidebell.com/' + store.url);
	};

	return (
		<Dialog
			open
			maxWidth="md"
			disableBackdropClick
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<div className={classes.dialogContent}>
				<Typography variant="h6" component="h2" id="alert-dialog-title">
					Welcome to CurbsideBell.com!
				</Typography>
				<Typography id="alert-dialog-description">
					We sent a welcome email to your inbox.
				</Typography>
				<div className={classes.actionsWrapper}>
					<Button
						variant="contained"
						color="primary"
						autoFocus
						href={process.env.REACT_APP_STORE_URL + 'dashboard'}
					>
						Open dashboard
					</Button>
					<Button variant="contained" color="primary" autoFocus href="/">
						Store Settings
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={copyStoreLinkToClipboard}
					>
						Copy Bell URL
					</Button>
				</div>
			</div>
		</Dialog>
	);
}

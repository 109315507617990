import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { cornerService } from '../../../services/CornerService';

const useStyles = makeStyles({
	root: {},
	map: {
		height: 400,
	},
});

function fromGoogle(list) {
	return list.map((e) => ({
		latitudes: e.lat(),
		longitudes: e.lng(),
	}));
}

function toGoogle(list) {
	return list.map((e) => ({
		lat: e.latitudes,
		lng: e.longitudes,
	}));
}

let mapRef;

export function Map({ allowedPingLocationCorners, center, onChange, polygon }) {
	const classes = useStyles();
	let mapEl = React.useRef(null);

	useEffect(() => {
		mapRef = new window.google.maps.Map(mapEl.current, {
			center: {
				lat: center.latitude,
				lng: center.longitude,
			},
			zoom: 14,
		});

		polygon.current = new window.google.maps.Polygon({
			strokeColor: '#023bca',
			strokeOpacity: 0.8,
			strokeWeight: 2,
			fillColor: '#0748eb',
			fillOpacity: 0.35,
			map: mapRef,
			editable: true,
		});

		window.polygon = polygon;
	}, []);

	useEffect(() => {
		polygon.current.setPaths(
			toGoogle(
				allowedPingLocationCorners //	 cornerService.getCornersForLocation(center.latitude, center.longitude)
			)
		);
		mapRef.setCenter({
			lat: center.latitude,
			lng: center.longitude,
		});
	}, [allowedPingLocationCorners]);

	useEffect(()  =>  {
		window.google.maps.event.addListener(
			polygon.current,
			'dblclick',
			function (mev) {
				if (mev.vertex != undefined) {
					let path = polygon.current.getPath();
					if (path.length > 3) {
						polygon.current.getPath().removeAt(mev.vertex);
					}
				}
			}
		);
		window.google.maps.event.addListener(
			polygon.current.getPath(),
			'insert_at',
			() => {
				onChange(fromGoogle(polygon.current.getPath().getArray()));
			}
		);

		window.google.maps.event.addListener(
			polygon.current.getPath(),
			'remove_at',
			() => {
				onChange(fromGoogle(polygon.current.getPath().getArray()));
			}
		);

		window.google.maps.event.addListener(
			polygon.current.getPath(),
			'set_at',
			() => {
				onChange(fromGoogle(polygon.current.getPath().getArray()));
			}
		);
	},  [allowedPingLocationCorners]);;

	const generateCorners = () => {
		onChange(
			cornerService.getCornersForLocation(center.latitude, center.longitude)
		);
		mapRef.setZoom(14);
	};

	return (
		<div className={classes.root}>
			<div className={classes.map} ref={mapEl}></div>
			<Button onClick={generateCorners}>Reset</Button>
		</div>
	);
}

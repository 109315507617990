import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip,
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { FileCopyOutlined, OpenInNew } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { apiService } from '../../services/ApiService';
import { toast } from 'react-toastify';
import { Fragment } from 'react';

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},
}));

export default function InviteCustomerDialog({ open, setOpen, storeInfo }) {
	const handleClose = () => {
		setOpen(false);
	};

	const { fields, storeConfig } = storeInfo;

	const linkRef = useRef();

	const [fieldValues, setFieldValues] = useState({});
	const [loading, setLoading] = useState(false);
	const [communicationType, setCommunicationType] = useState('Email');
	const [communicationInfo, setCommunicationInfo] = useState({
		emailAddress: '',
		phoneNumber: '',
	});
	const [emailContent, setEmailContent] = useState({
		body: storeConfig?.bellInviteBodyText || '',
		subject: storeConfig?.bellInviteSubject || '',
	});
	const [smsContent, setSmsContent] = useState(storeConfig?.smsConte || '');

	const generateLink = () => {
		let url = `${storeInfo.url}/start?`;
		Object.entries(fieldValues).forEach(([name, val]) => {
			url += `${encodeURIComponent(name)}=${encodeURIComponent(val)}&`;
		});
		return url;
	};

	const copyLink = () => {
		linkRef.current.select();
		linkRef.current.setSelectionRange(0, 99999);
		document.execCommand('copy');
		linkRef.current.focus();
	};

	const sendInvitation = async () => {
		setLoading(true);
		const data = {
			messagingMethod: communicationType,
			recipient: communicationInfo.emailAddress,
			linkPath: '/' + generateLink(),
			bodyText: emailContent.body,
			subject: emailContent.subject,
		};
		console.log(data);
		console.log(emailContent);
		apiService
			.post('store/bell-invite', data)
			.then((res) => {
				setLoading(false);
				handleClose();
				toast.success('Invitation sent');
				setFieldValues({});
				setCommunicationInfo({ emailAddress: '', phoneNumber: '' });
				setEmailContent({
					body: storeConfig?.bellInviteBodyText || '',
					subject: storeConfig?.bellInviteSubject || '',
				});
				setSmsContent('');
			})
			.catch((e) => {
				console.log(e);
				setLoading(false);
			});
	};

	const classes = useStyles();

	return (
		<div>
			<Dialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={open}
			>
				<DialogTitle id="customized-dialog-title" onClose={handleClose}>
					Invite Customer
				</DialogTitle>
				<DialogContent dividers>
					<div
						style={{
							display: 'grid',
							gridTemplateColumns: '1fr 1fr',
							gridColumnGap: 24,
						}}
					>
						<div>
							{fields &&
								fields.map((field) => (
									<Fragment key={field.name}>
										<TextField
											id={field.name}
											label={field.name}
											variant="outlined"
											name={field.name}
											autoComplete="off"
											fullWidth={true}
											style={{ margin: '0 0 12px 0' }}
											value={fieldValues[field.name]}
											onChange={({ target: { name, value } }) =>
												setFieldValues((f) => ({ ...f, [name]: value }))
											}
										/>
									</Fragment>
								))}
						</div>
						<div>
							<FormControl variant="outlined" fullWidth={true} focused>
								<InputLabel id="via">Send via</InputLabel>
								<Select
									label="Send via"
									id="via"
									value={communicationType}
									style={{ margin: '0 0 12px 0' }}
									onChange={(e) => setCommunicationType(e.target.value)}
								>
									<MenuItem value={'Email'}>Email</MenuItem>
									<MenuItem value={'Text'} disabled>
										SMS (Coming soon)
									</MenuItem>
								</Select>
							</FormControl>
							{communicationType === 'Email' ? (
								<TextField
									label={'Enter email address'}
									variant="outlined"
									style={{ margin: '0 6px 12px 0' }}
									required
									type={'email'}
									value={communicationInfo.emailAddress}
									fullWidth={true}
									onChange={({ target: { value } }) =>
										setCommunicationInfo({
											...communicationInfo,
											emailAddress: value,
										})
									}
								/>
							) : (
								<TextField
									label={'Enter phone number'}
									variant="outlined"
									style={{ margin: '0 6px 12px 0' }}
									required
									type={'tel'}
									value={communicationInfo.phoneNumber}
									fullWidth={true}
									onChange={({ target: { value } }) =>
										setCommunicationInfo({
											...communicationInfo,
											phoneNumber: value,
										})
									}
								/>
							)}

							<Button
								variant="contained"
								color="primary"
								fullWidth={true}
								disabled={loading}
								onClick={sendInvitation}
							>
								Send & close
							</Button>
						</div>
					</div>

					<p className="">
						<InputLabel id="via">Custom link for this customer</InputLabel>
						<Tooltip title="Click to copy" onClick={copyLink}>
							<input
								type="text"
								value={process.env.REACT_APP_PING_URL + generateLink()}
								ref={linkRef}
								readOnly
								style={{
									border: 'none',
									outline: 'none',
									width: '90%',
								}}
							/>
						</Tooltip>
						<Tooltip title="Click to copy" onClick={copyLink}>
							<FileCopyOutlined
								style={{ cursor: 'pointer', verticalAlign: 'middle' }}
							/>
						</Tooltip>
						<Tooltip title="Open in new tab">
							<a
								href={process.env.REACT_APP_PING_URL + generateLink()}
								target="_BLANK"
							>
								<OpenInNew
									style={{ verticalAlign: 'middle', color: 'black' }}
								/>
							</a>
						</Tooltip>
					</p>
				</DialogContent>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography className={classes.heading}>
							{communicationType === 'Email' ? 'Email body' : 'SMS body'}
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						{communicationType === 'Email' ? (
							<div>
								<TextField
									id="subject"
									label={'Subject'}
									variant="outlined"
									name={'subject'}
									fullWidth={true}
									style={{ marginBottom: 12 }}
									value={emailContent.subject}
									onChange={({ target: { value } }) => {
										setEmailContent((d) => ({
											...d,
											subject: value,
										}));
									}}
								/>
								<TextField
									id="body"
									label="Body"
									multiline
									fullWidth={true}
									rows={4}
									variant="outlined"
									value={emailContent.body}
									onChange={({ target: { value } }) => {
										setEmailContent((d) => ({
											...d,
											body: value,
										}));
									}}
								/>
							</div>
						) : (
							<TextField
								id="smsContent"
								label="Text message"
								multiline
								fullWidth={true}
								variant="outlined"
								value={smsContent}
								onChange={({ target: { value } }) => {
									setSmsContent(value);
								}}
							/>
						)}
					</AccordionDetails>
				</Accordion>
			</Dialog>
		</div>
	);
}

import React, { useEffect } from 'react';
import FieldComponent from './Field';
import { Field } from '../../../Models/Store.model';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	root: {
		display: 'grid',
		gridTemplateColumns: 'auto 40px 40px 50px',
		alignItems: 'center',
		gridGap: '6px',
	},
	columnHeader: {
		fontSize: 10,
	},
	addFieldButton: {
		gridColumn: '1 / -1',
		justifySelf: 'left',
	},
});

export default function FieldList({ fields, onChange }) {
	useEffect(() => {
		let el = document.querySelector(`.${classes.root}`);
		el = el?.children[el.children.length - 2].querySelector('input');
		el.focus();
	}, [fields.length]);

	const classes = useStyles();

	const handleChange = (field, i) => {
		fields[i] = field;
		onChange(Array.from(fields));
	};

	const handleRemove = (i) => {
		fields.splice(i, 1);
		onChange(fields);
	};

	const add = () => {
		fields.push(new Field());
		onChange(fields);
	};

	const setFieldAsPrimary = (index) => {
		fields = fields.map((f, i) => {
			if (index === i) {
				f.isPrimary = true;
				f.required = true;
			} else {
				f.isPrimary = false;
			}
			return f;
		});
		onChange(fields);
	};

	return (
		<div className={classes.root + ' hello'}>
			<span className={classes.columnHeader}></span>
			<span className={classes.columnHeader}>Primary</span>
			<span className={classes.columnHeader}>Required</span>
			<span className={classes.columnHeader}></span>
			{fields.map((f, i) => (
				<FieldComponent
					onChange={(e) => handleChange(e, i)}
					onRemove={() => handleRemove(i)}
					setAsPrimary={() => setFieldAsPrimary(i)}
					key={i}
					field={f}
				/>
			))}
			<Button
				className={classes.addFieldButton}
				variant="outlined"
				color="primary"
				onClick={add}
			>
				Add Field
			</Button>
		</div>
	);
}

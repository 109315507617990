import React from 'react';
import { TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	divider: {
		textAlign: 'center',
	},
	textField: {
		'& input': {
			textAlign: 'center',
			paddingLeft: 5,
			paddingRight: 5,
		},
		'& .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
			borderColor: 'transparent',
		},
		'&:not(:focus-within) ::-webkit-calendar-picker-indicator': {
			display: 'none',
		},
	},
});

export default function DayHours({ day, isOpen, opensAt, closesAt, onChange }) {
	const classes = useStyles();

	function setOpenness(open) {
		onChange({
			target: {
				checked: open,
				type: 'checkbox',
				name: 'open' + day,
			},
		});
	}

	return (
		<>
			<span>{day.substring(0, 3)}.</span>
			<TextField
				variant="outlined"
				size="small"
				className={classes.textField}
				name={'opensAt' + day}
				disabled={!isOpen}
				type="time"
				placeholder="HH:MM"
				value={opensAt || undefined}
				required={isOpen}
				onChange={onChange}
			/>
			<span className={classes.divider}>-</span>
			<TextField
				variant="outlined"
				size="small"
				className={classes.textField}
				name={'closesAt' + day}
				disabled={!isOpen}
				type="time"
				placeholder="HH:MM"
				value={closesAt || undefined}
				required={isOpen}
				onChange={onChange}
			/>
			{isOpen ? (
				<Button onClick={() => setOpenness(false)} size="small">
					Close
				</Button>
			) : (
				<Button onClick={() => setOpenness(true)} size="small">
					Re-open
				</Button>
			)}
		</>
	);
}
